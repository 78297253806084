import React, { useState } from 'react';

import Search from '../../Components/Search'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function SearchRegionalManager(props) {
    const [ loadingRegionalManager, setLoadingRegionalManager ] = useState(false);   

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");
    const [ cpfSearchErro, setCpfSearchErro] = useState(false);
    const [ cpfSearchErroMessage, setCpfSearchErroMessage ] = useState("");
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
    const removeInputMask = (cpf) => {
        if (cpf === "") {
            return ""
        } else {
            return cpf.replace(/[^\d]+/g, "");
        }
    };
    const onSubmitCPF = async (cpf) => {
        setCpfSearchErro(false);
        cpf = removeInputMask(cpf)
        setLoadingRegionalManager(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_orgao: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).codigo_orgao : "",
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",  //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                cpf_gestor_regional: cpf
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaGestorRegional`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data) {

                    const regionalManager = {
                        cpf: data.cpf_gestor_regional,
                        name: data.nome_gestor_regional,
                        dt_canc_exp: '',
                        ident_dig: data.id_digital
                    }
                    setLoadingRegionalManager(false);

                    props.history.push("/adicionarGestorRegional", regionalManager);

                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setCpfSearchErro(true);
                        setCpfSearchErroMessage(data.message);
                    } else {
                        // inserir mensagem genérica
                        setCpfSearchErro(true);
                        setCpfSearchErroMessage('Houve algum problema no servidor.');
                    }

                    setLoadingRegionalManager(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingRegionalManager(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingRegionalManager(false);
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return ( 
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <Search
                pageTitle='Cadastrar gestor regional'
                buttonBackRoute='/gerenciarGestoresRegionais'
                messageError={messageError}
                onSubmitCPF={onSubmitCPF}
                modalError={modalError}
                setModalError={setModalError}
                buttonNextLoading={loadingRegionalManager}
                cpfSearchErro={cpfSearchErro}
                setCpfSearchErro={setCpfSearchErro}
                cpfSearchErroMessage={cpfSearchErroMessage}
                history={props.history}
            />
        </>
    );
}