import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    GlobalContainer,
    ContentContainer,
    ContentContainerTable,
    ContentContainer1,
    ContentContainer2,
    ContentContainer11,
    ContentContainer12,
    Title,
    Text,
    Textarea,
    TitleContainer,
    ContentContainerButtons
} from './styles';
import MessageAlert from '../Messages/AlertManager/index';
import ModalWarn from '../Modal/Warn/index';
import ModalCancel from '../Modal/Cancel/index';
import ModalSuccess from '../Messages/Success/index';
import ModalConfirm from '../Modal/Confirm/index';
import { cpfMask, formatDate } from '../../utils'; 

export default function BlockOperator(props) {
    const [defaultFormValues, setDefaultFormValues] = useState({
        cpf_usuario: "",
        name: "",
        organ: "",
        unidade: ""
    });

    const { handleSubmit } = useForm({ defaultFormValues });
    
    useEffect(() => {
        setDefaultFormValues(props.operator);
        window.scrollTo(0, 0);
    }, [props.operator])

    const [isModalCancelVisible, setModalCancelVisible] = useState(false);
    const [isModalConfirmVisible, setModalConfirmVisible] = useState(false);

    // ================================================================================

    return (
        <>
            {!props.loadOperator ?
                <div style={{ 'marginTop': '75px' }}> Carregando... </div>
                :
                <>
                    <ModalCancel
                        id="modalcancelalert"
                        appear={isModalCancelVisible}
                        text={"Tem certeza que deseja sair?"}
                        actionButton1={() => setModalCancelVisible(false)}
                        actionButton2={() => props.history.push('/pesquisarcidadao')}
                    />

                    <ModalWarn
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text={props.messageError}
                        textButton2='Ok'
                        actionButton2={() => props.setModalError(false)}
                    />

                    <ModalConfirm
                        id="modalconfirmlert"
                        appear={isModalConfirmVisible}
                        title={defaultFormValues.data_bloqueio ? 'Desbloquear usuário' : 'Bloquear usuário'}
                        text={defaultFormValues.data_bloqueio ? 'Tem certeza que deseja desbloquear este usuário?' : 'Tem certeza que deseja bloquear este usuário?'}
                        textButton1="Cancelar"
                        actionButton1={() => setModalConfirmVisible(false)}
                        textButton2="Sim"
                        actionButton2={(e) => { document.getElementById('submit').click(); setModalConfirmVisible(false); }}
                        textButton3="Sim"
                    />

                    <GlobalContainer>
                        {props.fromSuccessMsg &&
                            <ModalSuccess text={props.fromSuccessMsg}/>
                        }
                        {props.operator.ident_dig === "false" && <MessageAlert cpf={props.operator.cpf_usuario} />}

                        <TitleContainer>
                            <Title> {props.pageTitle} </Title>
                        </TitleContainer>

                        <form id="formBlock" onSubmit={handleSubmit(() => props.onSubmitData(defaultFormValues))}>
                            <ContentContainer>
                                <ContentContainer1>
                                    <ContentContainer11>
                                        <label> CPF </label>
                                        <Text> {defaultFormValues.cpf_usuario && cpfMask(defaultFormValues.cpf_usuario)} </Text>
                                    </ContentContainer11>

                                    <ContentContainer12>
                                        <label> Nome </label>
                                        <Text> {defaultFormValues.name} </Text>
                                    </ContentContainer12>
                                
                                    <ContentContainerTable>
                                        {console.log('*** defaultFormValues ***', defaultFormValues)}
                                        <div className="br-table" data-search="data-search" data-selection="data-selection">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th scope="col0" style={{'color': '#1351B4'}}></th>
                                                        <th scope="col1" style={{'color': '#1351B4'}}> Órgão </th>
                                                        <th scope="col2" style={{'color': '#1351B4'}}> Unidades </th>                                             
                                                        <th scope="col3" style={{'color': '#1351B4'}}> Data de Cadastro </th>
                                                        <th scope="col4" style={{'color': '#1351B4'}}> Data de Expiração </th>
                                                        <th scope="col99" style={{'color': '#1351B4'}}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> </td>
                                                        <td> {defaultFormValues.nome_orgao} </td>
                                                        <td> {defaultFormValues?.unidades_vinculadas?.map(u => u.NOME_UNIDADE)?.join(', ')} </td>
                                                        <td> { formatDate(defaultFormValues.data_inclusao) } </td>
                                                        <td> {defaultFormValues.data_expiracao ? formatDate(defaultFormValues.data_expiracao) : '-' } </td>  
                                                        <td> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ContentContainerTable>

                                    {defaultFormValues.cpf_usuario_cadastrador &&
                                        <ContentContainerTable>
                                            <div className="br-table" data-search="data-search" data-selection="data-selection">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col0" style={{'color': '#1351B4'}}></th>
                                                            <th scope="col1" style={{'color': '#1351B4'}}> CPF do(a) cadastrador(a) </th>
                                                            <th scope="col2" style={{'color': '#1351B4'}}> Nome do(a) cadastrador(a) </th>                                             
                                                            <th scope="col99" style={{'color': '#1351B4'}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td> </td>
                                                            <td> {cpfMask(defaultFormValues.cpf_usuario_cadastrador)} </td>
                                                            <td> {defaultFormValues.nome_usuario_cadastrador} </td>                                          
                                                            <td> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ContentContainerTable>
                                    }
   
                                    {defaultFormValues.data_bloqueio &&
                                        <ContentContainerTable>
                                            <div className="br-table" data-search="data-search" data-selection="data-selection">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col0" style={{'color': '#1351B4'}}></th>
                                                            <th scope="col1" style={{'color': '#1351B4'}}> Data do bloqueio </th>
                                                            <th scope="col2" style={{'color': '#1351B4'}}> Motivo do bloqueio </th>                                             
                                                            <th scope="col99" style={{'color': '#1351B4'}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td> </td>
                                                            <td> { formatDate(defaultFormValues.data_bloqueio)} </td>
                                                            <td> {defaultFormValues.motivo_bloqueio}</td>                                          
                                                            <td> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ContentContainerTable>
                                    }
                                    {defaultFormValues.data_desbloqueio &&
                                        <ContentContainerTable>
                                            <div className="br-table" data-search="data-search" data-selection="data-selection">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col0" style={{'color': '#1351B4'}}></th>
                                                            <th scope="col1" style={{'color': '#1351B4'}}> Data do desbloqueio </th>
                                                            <th scope="col2" style={{'color': '#1351B4'}}> Motivo do desbloqueio </th>                                             
                                                            <th scope="col99" style={{'color': '#1351B4'}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td> </td>
                                                            <td> { formatDate(defaultFormValues.data_desbloqueio)} </td>
                                                            <td> {defaultFormValues.motivo_desbloqueio}</td>                                          
                                                            <td> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ContentContainerTable>
                                    }
                                    {!defaultFormValues.data_bloqueio &&
                                        <ContentContainer12>
                                            <label> Motivo do bloqueio </label>
                                                <Textarea name="motivo_bloqueio"
                                                    defaultValue = {defaultFormValues.motivo_bloqueio}
                                                    onChange={(event) => {setDefaultFormValues({ ...defaultFormValues, motivo_bloqueio: event.target.value })}}
                                                    rows="4" cols="200"/>
                                        </ContentContainer12>
                                    }
                                    {defaultFormValues.data_bloqueio &&
                                        <ContentContainer12>
                                            <label> Motivo do desbloqueio </label>
                                                <Textarea name="motivo_desbloqueio"
                                                    defaultValue = {defaultFormValues.motivo_desbloqueio}
                                                    onChange={(event) => {setDefaultFormValues({ ...defaultFormValues, motivo_desbloqueio: event.target.value })}}
                                                    rows="4" cols="200"/>
                                        </ContentContainer12>
                                    }

                                </ContentContainer1>
                            </ContentContainer>

                            <ContentContainer>
                                <ContentContainer1>

                                </ContentContainer1>

                                <ContentContainer2>
                                    <ContentContainerButtons>
                                        <button className="br-button" type="button"
                                            onClick={() => setModalCancelVisible(true)}>
                                            Voltar
                                        </button>
                                        
                                        {!props.loadingAdd ?
                                            <button className="br-button primary" type="button"
                                                onClick={() => setModalConfirmVisible(true)}>
                                                {defaultFormValues.data_bloqueio ? 'Desbloquear' : 'Bloquear'}
                                            </button>                                            
                                            :
                                            <button className="br-button primary loading" type="button">
                                                {defaultFormValues.data_bloqueio ? 'Desbloquear' : 'Bloquear'}
                                            </button>
                                        }
                                    </ContentContainerButtons>
                                </ContentContainer2>
                            </ContentContainer>
                            <input id="submit" type="submit" style={{display:"none"}}></input>
                        </form>
                    </GlobalContainer>
                </>
            }
        </>
    );
}