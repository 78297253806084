import React, { useEffect, useState } from 'react'
import UnitManagerScheduleCurrentDetailsManagement from './UnitManagerScheduleCurrentDetailsManagement'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function UnitManagerScheduleCurrent(props) {
  const dias = [
    { label: 'Seg à Sex', value: 'Seg à Sex' },
    { label: 'Seg a Sáb', value: 'Seg a Sáb' },
    { label: 'Seg a Dom', value: 'Seg a Dom' },
    { label: 'Sábado', value: 'Sábado' },
    { label: 'Domingo', value: 'Domingo' },
    { label: 'Final de Semana', value: 'Final de Semana' },
  ]
  const [successMsg, setSuccessMsg] = useState('')
  const [loadUnit, setLoadUnit] = useState(false)
  const [erroMsg, setErroMsg] = useState([])
  const [orgaoSchedule, setOrgaoSchedule] = useState('')
  const [loadingButton, setLoadingButton] = useState(false)
  const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
  const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
  const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
  const [unit, setUnit] = useState({})

  useEffect(() => {
    async function loadUnitSchedule() {
      setLoadingButton(true)
      const user = JSON.parse(localStorage.getItem('user'))
      const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: user ? user.token : '',
        }),
        body: JSON.stringify({
          cpf_gestor: user.cpf_atendente,
          codigo_orgao: user.codigo_orgao,
          codigo_unidade: user.codigo_unidade
        }),
      }
      await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/consultaHorarioUnidade`, requestOptions)
        .then(function (response) {
          if (response.ok) {
            response.json().then(function (data) {
              // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
              setUnit({
                name: data.NOME_UNIDADE,
                organ: { value: data.CODIGO_ORGAO, label: data.NOME_ORGAO },
                unidade: { value: data.CODIGO_UNIDADE, label: data.NOME_UNIDADE },
                dias_semana: data.DIAS_SEMANA ? data.DIAS_SEMANA : dias[0].value,
                hora_inicio: data.HORA_INICIO,
                hora_fim: data.HORA_FIM,
                data_limite: !!data.DATA_LIMITE ? data.DATA_LIMITE.split('T')[0] : '',
                motivo: data.MOTIVO_HORARIO_EXCECAO,
              })
            })
          } else if (response.status === 422) {
            response.json().then(function (data) {
              if (!!data.errorCode && !!data.message) {
                setErroMsg(data.message)
                console.log(data.message)
              } else {
                // inserir mensagem genérica
                console.log('Response error in gestao/horario/consultaHorarioUnidade BackendBalcao')
              }
            })
          } else if (response.status === 403) {
            response.json().then(function (data) {
              setModalInvalidSessionErrorTitle(data.title ? data.title : null)
              setModalInvalidSessionErrorMsg(data.message ? data.message : null)
              setModalInvalidSessionError(true)
            })
          } else {
            // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
            //setLoading(false);
            //setModalError(true);
            console.log('Request error in gestao/horario/consultaHorarioUnidade BackendBalcao')
          }
        })
        .catch(function (err) {
          console.error('Failed retrieving information', err)
        })
      setLoadingButton(false)
    }

    async function loadDefaultOrganSchedule() {
      setLoadingButton(true)
      const user = JSON.parse(localStorage.getItem('user'))
      const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: user ? user.token : '',
        }),
        body: JSON.stringify({
          cpf_gestor: user.cpf_atendente,
          codigo_orgao: user.codigo_orgao,
        }),
      }
      await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/consultaHorarioOrgao`, requestOptions)
        .then(function (response) {
          if (response.ok) {
            response.json().then(function (data) {
              // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
              setOrgaoSchedule({
                codigo_orgao: data.CODIGO_ORGAO,
                nome_orgao: data.NOME_ORGAO,
                dias_semana: data.DIAS_SEMANA,
                hora_inicio: data.HORA_INICIO,
                hora_fim: data.HORA_FIM,
              })
            })
          } else if (response.status === 422) {
            response.json().then(function (data) {
              if (!!data.errorCode && !!data.message) {
                // inserir a mensagem: data.message no aviso de erro
                console.log(data.message)
              } else {
                // inserir mensagem genérica
                console.log('Response error in gestao/detalhaUnidade BackendBalcao')
              }
            })
          } else if (response.status === 403) {
            response.json().then(function (data) {
              setModalInvalidSessionErrorTitle(data.title ? data.title : null)
              setModalInvalidSessionErrorMsg(data.message ? data.message : null)
              setModalInvalidSessionError(true)
            })
          } else {
            // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
            //setLoading(false);
            //setModalError(true);
            console.log('Request error in gestao/detalhaUnidade BackendBalcao')
          }
        })
        .catch(function (err) {
          console.error('Failed retrieving information', err)
        })
        setLoadingButton(false)
    }
    loadUnitSchedule()
    loadDefaultOrganSchedule()
    setLoadUnit(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const undoException = async (codigoUnidade) => {
    const user = JSON.parse(localStorage.getItem('user'))

    const requestOptions = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: user ? user.token : '',
      }),

      body: JSON.stringify({
        cpf_gestor: user.cpf_atendente,
        codigo_orgao: user.codigo_orgao,
        codigo_unidade: codigoUnidade
      }),
    }

    await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/apagaHorarioUnidade`, requestOptions)
      .then(function (response) {
        if (response.ok) {
          response.json().then(function () {
            setUnit({
              name: unit.name,
              organ: unit.organ,
              unidade: unit.unidade,
              dias_semana: dias[0].value,
              hora_inicio: '',
              hora_fim: '',
              data_limite: '',
              motivo: '',
            })
            setSuccessMsg('Horário de exceção desfeito')
            setErroMsg(undefined)
          })
        } else {
          response.json().then(function (data) {
            setErroMsg(data.messages ? data.messages : data.message)
            console.log('Error request to /gestao/horario/apagaHorarioUnidade', data)
          })
        }
      })
      .catch(function (err) {
        console.error('Failed retrieving information', err)
      })
  }

  async function confirmUpdate() {
    setLoadingButton(true)
    const user = JSON.parse(localStorage.getItem('user'))

    const requestOptions = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: user ? user.token : '',
      }),

      body: JSON.stringify({
        cpf_gestor: user.cpf_atendente,
        codigo_orgao: unit.organ.value,
        codigo_unidade: unit.unidade.value,
        dias_semana: unit.dias_semana,
        hora_inicio: unit.hora_inicio,
        hora_fim: unit.hora_fim,
        data_limite: unit.data_limite,
        motivo_horario_excecao: unit.motivo,
      }),
    }

    await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/editaHorarioUnidade`, requestOptions)
      .then(function (response) {
        if (response.ok) {
          response.json().then(function (data) {
            setSuccessMsg(data.message)
            setErroMsg(undefined)
          })
        } else {
          response.json().then(function (data) {
            setErroMsg(data.messages ? data.messages : data.message)
            console.log('Erro no request de /gestao/horario/editaHorarioUnidade')
          })
        }
      })
      .catch(function (err) {
        console.error('Failed retrieving information', err)
      })
    setLoadingButton(false)
    window.scrollTo(0,0)
  }

  return (
    <>
      <ModalWarn
          id='modalWarnalertInvalidSession'
          appear={modalInvalidSessionError}
          title = { modalInvalidSessionErrorTitle }
          text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
          textButton2='Ir para tela inicial'
          actionButton2={() => { 
              document.getElementById("btnLogout").click();
          }}
      />
      <UnitManagerScheduleCurrentDetailsManagement
        pageTitle='Horário de exceção da Unidade corrente'
        pageGuidance='Para mudar a unidade corrente para outra unidade a qual você se encontra vinculado(a), basta clicar no nome da unidade corrente no menu superior direito.'
        pageGuidance2='O novo horário de exceção cadastrado abaixo será aplicado para todos os Atendentes desta unidade.'
        days={dias}
        unit={unit}
        setUnit={setUnit}
        loadOperator={loadUnit}
        buttonBackRoute='/pesquisarcidadao'
        buttonEditRoute='/editarHorarioUnidade'
        confirmUpdate={confirmUpdate}
        orgaoSchedule={orgaoSchedule}
        history={props.history}
        erroMsg={erroMsg}
        fromSuccessMsg={successMsg}
        loadingButton={loadingButton}
        buttonUndoException={undoException}
      />
    </>
  )
}
