import React  from 'react';

import { Text, Text2, Text3, HeaderUp } from './styles';
import ModalUnitChoice from '../../Components/Modal/UnitChoice'
//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';

//import jwt from 'jwt-decode';

import UserIcon from '../../assets/user_icon.png';

class Header extends React.Component {
    constructor(props) {
        super(props);
        const userPictureContainsData = /^data:/.test(this.props.handleUser.picture)
        const userPictureContainsBase64 = /.?base64,?/.test(this.props.handleUser.picture)
        const userInfo = JSON.parse(localStorage.getItem('user'));
        this.state = { 
            name: props.handleUser.name,
            picture: (userPictureContainsData && userPictureContainsBase64)? this.props.handleUser.picture : " ",
            organ: props.handleUser.organ,
            perfil: props.handleUser.perfil,
            modalUnitOption: false,
            listaUnidadesOptions: userInfo && userInfo.lista_unidades_options
        };
    };

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.handleUser !== prevProps.handleUser) {
            const userPictureContainsData = /^data:/.test(this.props.handleUser.picture)
            const userPictureContainsBase64 = /.?base64,?/.test(this.props.handleUser.picture)
            const userInfo = JSON.parse(localStorage.getItem('user'));
            this.setState({
            name: this.props.handleUser.name,
            picture: (userPictureContainsData && userPictureContainsBase64)? this.props.handleUser.picture : " ",
            organ: this.props.handleUser.organ,
            perfil: this.props.handleUser.perfil,
            unit: JSON.parse(localStorage.getItem('user')).unidade,
            modalUnitOption: false,
            listaUnidadesOptions: userInfo && userInfo.lista_unidades_options
          });
        }
    };
    
    render() {
        return (
            <>
                <HeaderUp showInfo={this.props.showInfo}>
                    <div className="br-header">
                        <div className="container-lg">
                            <div className="header-top">
                                <div className="header-logo">
                                    <a href="https://www.gov.br/pt-br" rel="noreferrer" target="_blank">
                                        <img 
                                            src="https://upload.wikimedia.org/wikipedia/commons/1/11/Gov.br_logo.svg" 
                                            alt="govlogo" 
                                        />
                                    </a>
                                </div>
                                <div className="header-actions" id='UserInfo'>

                                    <div style={{ 'marginRight': '10px', 'display': 'grid' }}>
                                        <Text2>
                                            {
                                                {
                                                    S: 'Administrador,',
                                                    O: 'Gestor(a) de Órgão,',
                                                    R: 'Gestor(a) Regional,',
                                                    A: 'Atendente,',
                                                }[this.state.perfil] || null
                                            }
                                        </Text2>
                                        <Text>
                                            {this.state.name}
                                        </Text>
                                        <Text2>
                                            {this.state.organ}
                                        </Text2>
                                        {['R', 'A'].includes(this.state.perfil) &&
                                            <Text3 onClick={()=>{if (this.state.listaUnidadesOptions.length > 0) this.setState({...this.state, modalUnitOption: true})}}>
                                                {this.state.unit}
                                            </Text3>
                                        }
                                    </div>

                                    <div className="header-avatar">
                                        <span className="br-avatar" title="Foto do usuário">
                                            <span className="image">
                                                <img src={this.state.picture.trim() || UserIcon} alt="userPicture"/>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="header-login">
                                        <div className="header-sign-in">
                                            <button id="btnLogout" className="br-button sign-in" 
                                                    type="button"
                                                    onClick={() => {      
                                                        fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                                                            method: 'GET',
                                                            headers: {
                                                            authorization: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
                                                            Accept: 'application/json',
                                                            },
                                                        });                                              
                                                        const { REACT_APP_LOGIN_UNICO_URL,REACT_APP_REDIRECT_URI_LOGOUT} = process.env;//, REACT_APP_RESPONSE_TYPE, REACT_APP_CLIENT_ID, REACT_APP_SCOPE, REACT_APP_REDIRECT_URI, REACT_APP_NONCE, REACT_APP_STATE } 
                                                        // const urlLogin = `${REACT_APP_LOGIN_UNICO_URL}/authorize?response_type=${REACT_APP_RESPONSE_TYPE}&client_id=${REACT_APP_CLIENT_ID}&scope=${REACT_APP_SCOPE}&redirect_uri=${REACT_APP_REDIRECT_URI}&nonce=${REACT_APP_NONCE}&state=${REACT_APP_STATE}`;
                                                        localStorage.clear();                                                                                                        
                                                        const possibleURLs = ["https://sso.staging.acesso.gov.br", "https://sso.acesso.gov.br"];
                                                        if (possibleURLs.includes(REACT_APP_LOGIN_UNICO_URL)) {
                                                            window.location.href = `${REACT_APP_LOGIN_UNICO_URL}/logout?post_logout_redirect_uri=${REACT_APP_REDIRECT_URI_LOGOUT}`}        
                                                            else { alert("Error: URL não está contida na lista de possívels URLs do sistema. Contacte o suporte")}                                                      
                                                        }}>
                                                Sair
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </HeaderUp>
                <ModalUnitChoice 
                    id="modalUnitOption"
                    appear={this.state.modalUnitOption}
                    unitListOptions={this.state.listaUnidadesOptions}
                    title={"Escolha sua unidade."}
                    textButton1={"Ok"}    
                    errorMessage={"Por favor, escolha sua unidade."}
                    actionButton1={(chosenUnit) => {
                        let user = JSON.parse(localStorage.getItem('user'))
                        user.codigo_unidade = chosenUnit.value;
                        user.unidade = chosenUnit.label;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.setState({...this.state, unit: user.unidade, modalUnitOption: false})
                        window.location.reload();
                    }}
                />
            </>
        );
    }
}

export default Header;