import React, { useEffect, useState } from 'react';

import ShowOperator from '../../Components/ShowOperator';

export default function ShowAttendant(props) {
    const [ attendant, setAttendant ] = useState({
        from: '',
        id_usuario_orgao: '',
        cpf: '',
        name: '',
        organ: '',
        dt_inc: '',
        dt_canc_exp: ''
    });

    const [ loadAttendant, setLoadAttendant ] = useState(false);
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });

    useEffect(() => {
        if (!loadAttendant && props.location.state !== undefined) {
            setAttendant({
                from: props.location.state.from,
                id_usuario_orgao: props.location.state.id_usuario_orgao,
                cpf: props.location.state.cpf,
                name: props.location.state.name,
                organ: props.location.state.organ,
                unidade: props.location.state.unidade,
                dt_inc: props.location.state.dt_inc,
                dt_canc_exp: props.location.state.dt_canc_exp
            });
            setLoadAttendant(true);
        }
    }, [props.location.state, loadAttendant]);

    return (
        <ShowOperator
            pageTitle={attendant.from === "addAttendant" ? 'Atendente Cadastrado': 'Atendente Atualizado'}
            operator={attendant} 
            setOperator={setAttendant}
            loadOperator={loadAttendant}
            fromSuccessMsg={attendant.from === "addAttendant" ? (attendant.dt_canc_exp ? 'Atendente cadastrado com sucesso. A data de expiração será aplicada para todas as unidades na qual o atendente está vinculado.' : 'Atendente cadastrado com sucesso.') : (attendant.dt_canc_exp ? 'Atendente atualizado com sucesso. A data de expiração será aplicada para todas as unidades na qual o atendente está vinculado.' : 'Atendente atualizado com sucesso.')}
            buttonBackRoute='/gerenciaratendentes'
            buttonEditRoute='/editaratendente'
            buttonRegistrationTitle='+ Cadastrar Atendente'
            buttonRegistrationRoute='/consultaratendente'
            history={props.history}
            rangePages={rangePages}
            setRangePages={setRangePages}
        />
    );
}