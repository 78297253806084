import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  GlobalContainer,
  ContentContainer,
  TitleContainer,
  Title,
  Subtitle,
  Line,
  SearchContainer,
  SelectPagesContainer,
  InputContainer,
  SearchButtonsContainer,
  PaginationContainer,
  InputContainer2,
} from './styles'
import Select, { components } from 'react-select'
import ModalCancel from '../../Components/Modal/Cancel/index'
import DropDownIcon from '../../assets/drop_down_icon.png'
import DropUpIcon from '../../assets/drop_up_icon.png'
import DropRightIcon from '../../assets/drop_right_icon.png'
import DropLeftIcon from '../../assets/drop_left_icon.png'
import FailIcon from '../../assets/fail_icon.png'
import ViewIcon from '../../assets/eye_icon.png'
import EditIcon from '../../assets/edit_icon.png'
import UndoIcon from '../../assets/undo_icon.png'
import FilterIcon from '../../assets/filter_icon.png'
import AlertIcon from '../../assets/alert_icon2.png'
import { cpfMask, formatDate } from '../../utils'
import { profile } from '../../constants'
import ModalWarn from '../../Components/Modal/Warn/index'
import ModalConfirm from '../../Components/Modal/Confirm'

export default function AttendantScheduleManagement(props) {
  const [defaultFormValues, setDefaultFormValues] = useState({
    cpf: '',
    name: '',
    organ: '',
    exception: false,
  })

  const [organ, setOrgan] = useState({
    value: JSON.parse(localStorage.getItem('user')).codigo_orgao ? JSON.parse(localStorage.getItem('user')).codigo_orgao : '0',
    label: JSON.parse(localStorage.getItem('user')).orgao ? JSON.parse(localStorage.getItem('user')).orgao : 'Todos os órgãos',
  })

  const [unidade, setUnidade] = useState({
    value: JSON.parse(localStorage.getItem('user')).codigo_unidade ? JSON.parse(localStorage.getItem('user')).codigo_unidade : '0',
    label: JSON.parse(localStorage.getItem('user')).unidade ? JSON.parse(localStorage.getItem('user')).unidade : 'Todas as unidades',
  })

  const [isModalConfirmVisible, setModalConfirmVisible] = useState(false)

  useEffect(() => {
    setDefaultFormValues({ ...defaultFormValues, organ: organ.value, unidade: unidade.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, errors } = useForm({ defaultFormValues })

  const [rangePagesOptions] = useState([
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '30', label: '30' },
  ])

  const SelecPageStyles = {
    control: (defaultStyles, state) => ({
      ...defaultStyles,
      borderWidth: 0,
      borderColor: state.isFocused ? '#E60000' : '#888',
      '&:hover': {
        backgroundColor: '#C5D4EB',
      },
      marginTop: 15,
    }),
  }

  const DropdownIndicator = (props) => {
    return <components.DropdownIndicator {...props}>{props.selectProps.menuIsOpen ? <img src={DropUpIcon} alt='upicon' /> : <img src={DropDownIcon} alt='downicon' />}</components.DropdownIndicator>
  }

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={{}} {...innerProps} />
  }

  const [modalCancel, setModalCancel] = useState(false)
  const [registro, setRegistro] = useState(false)

  const perfil = JSON.parse(localStorage.getItem('user')).perfil

  return (
    <>
      <ModalCancel
        id='modalcancelalert'
        appear={modalCancel}
        text={'Deseja cancelar a pesquisa e retornar para página principal?'}
        actionButton1={() => setModalCancel(false)}
        actionButton2={() => props.history.push('/pesquisarcidadao')}
      />
      <ModalWarn
        id='modalconfirmalert'
        appear={props.modalError}
        text={'Houve algum problema no servidor. Aguarde um momento e tente novamente.'}
        textButton2='Ok'
        actionButton2={() => props.setModalError(false)}
      />

      <ModalConfirm
        id='modalconfirmlert'
        appear={isModalConfirmVisible}
        title='Confirmar a volta para o horário normal?'
        text='Tem certeza que deseja voltar para o horário normal?'
        textButton1='Cancelar'
        actionButton1={() => setModalConfirmVisible(false)}
        textButton2='Sim'
        actionButton2={(e) => {
          props.buttonUndoException(registro)
          setModalConfirmVisible(false)
        }}
        textButton3='Sim'
      />

      <GlobalContainer>
        <ContentContainer>
          <TitleContainer>
            <Title>{props.pageTitle}</Title>
          </TitleContainer>

          <Subtitle>
            <img src={FilterIcon} alt='filterIcon' style={{ marginBottom: '7px', marginRight: '7px' }} />
            <label> Filtro </label>
            <Line />
          </Subtitle>

          <form onSubmit={handleSubmit(() => props.onSubmitSearch(defaultFormValues))}>
            <SearchContainer>
              <InputContainer>
                <div className='br-input'>
                  <label htmlFor='cpf'> CPF </label>
                  <input
                    id='cpf'
                    name='cpf'
                    placeholder='Digite somente números'
                    type='text'
                    maxLength='14'
                    defaultValue={cpfMask(defaultFormValues.cpf)}
                    ref={register({
                      required: false,
                      minLength: { value: 14, message: 'CPF inválido.' },
                    })}
                    onChange={(event) => {
                      const { value } = event.target
                      setDefaultFormValues({ ...defaultFormValues, cpf: value })
                      event.target.value = cpfMask(value)
                      props.setSearchResult([])
                      props.setErroMsg(undefined)
                    }}
                  />
                </div>
                {errors?.cpf && (
                  <span className='feedback danger' role='alert'>
                    <i className='fas fa-times-circle' aria-hidden='true'>
                      <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                    </i>
                    <label>{errors.cpf.message}</label>
                  </span>
                )}
              </InputContainer>

              <InputContainer>
                <div className='br-input'>
                  <label htmlFor='name'> Nome </label>
                  <input
                    id='name'
                    name='name'
                    type='text'
                    placeholder={props.fieldNamePlaceholder}
                    defaultValue={defaultFormValues.name}
                    onChange={(event) => {
                      const { value } = event.target
                      setDefaultFormValues({ ...defaultFormValues, name: value })
                      props.setSearchResult([])
                      props.setErroMsg(undefined)
                    }}
                  />
                </div>
                {errors?.name && (
                  <span className='feedback danger' role='alert'>
                    <i className='fas fa-times-circle' aria-hidden='true'>
                      <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                    </i>
                    {errors.name.message}
                  </span>
                )}
              </InputContainer>
            </SearchContainer>

            <InputContainer2>
              <input
                name='only-exception'
                type='checkbox'
                ref={register({
                  required: false,
                })}
                onChange={(event) => {
                  setDefaultFormValues({ ...defaultFormValues, exception: !defaultFormValues.exception })
                  props.setSearchResult([])
                  props.setErroMsg(undefined)
                }}
              />
              <label htmlFor='exception'>Apenas os horários de exceção</label>
            </InputContainer2>

            <SearchButtonsContainer>
              <button className='br-button secundary' type='button' onClick={() => setModalCancel(true)}>
                Cancelar
              </button>
              <button
                className='br-button secondary'
                type='button'
                onClick={() => {
                  setDefaultFormValues({ ...defaultFormValues, cpf: '', name: '' })
                  if (perfil === profile.atendente) {
                    setOrgan({ value: '0', label: 'Selecione o órgão' })
                    setDefaultFormValues({ ...defaultFormValues, organ: '0' })
                  }
                  if (perfil !== profile.gestorRegional) {
                    setUnidade({ value: '0', label: 'Todas as unidades' })
                    setDefaultFormValues({ ...defaultFormValues, unidade: '0' })
                  }
                  props.setSearchResult([])
                  setDefaultFormValues({ ...defaultFormValues, cpf: '', name: '' })
                  document.getElementById('name').value = ''
                  document.getElementById('cpf').value = ''
                  props.setErroMsg(undefined)
                }}>
                Limpar Filtro
              </button>
              {!props.buttonNextLoading ? (
                <button className='br-button primary' type='submit'>
                  Pesquisar
                </button>
              ) : (
                <button className='br-button primary loading' type='button'>
                  Pesquisar
                </button>
              )}
            </SearchButtonsContainer>

            <Subtitle>
              <Line />
            </Subtitle>
          </form>

          {!!props.searchResult.length && (
            <>
              <Subtitle>
                <label>{props.tableResultListTitle}</label>
              </Subtitle>
              <div className='br-table' data-search='data-search' data-selection='data-selection'>
                <table>
                  <thead>
                    <tr>
                      <th scope='col0' style={{ color: '#1351B4' }}></th>
                      <th scope='col1' style={{ color: '#1351B4' }}>
                        Nome completo
                      </th>
                      <th scope='col2' style={{ color: '#1351B4' }}>
                        CPF
                      </th>
                      <th scope='col3' style={{ color: '#1351B4' }}>
                        Unidade do Órgão
                      </th>
                      <th scope='col4' style={{ color: '#1351B4' }}>
                        Dias da semana
                      </th>
                      <th scope='col4' style={{ color: '#1351B4' }}>
                        Horários
                      </th>
                      <th scope='col5' style={{ color: '#1351B4' }}>
                        Data limite
                      </th>
                      <th scope='col8' style={{ color: '#1351B4' }}>
                        Ações
                      </th>
                      <th scope='col99' style={{ color: '#1351B4' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.searchResult.map((result) => (
                      <tr key={result.ID_USUARIO_ORGAO}>
                        <td> </td>
                        <td> {result.NOME_USUARIO} </td>
                        <td> {cpfMask(result.CPF_USUARIO)}</td>
                        <td> {result.NOME_UNIDADE} </td>
                        <td> {result.DIAS_SEMANA} </td>
                        <td> {`${result.HORA_INICIO} até ${result.HORA_FIM}`} </td>
                        <td> {result.DATA_LIMITE && formatDate(result.DATA_LIMITE)} </td>

                        {result.DATA_BLOQUEIO && <td style={{ color: '#b41313' }}>BLOQUEADO</td>}
                        {!result.DATA_BLOQUEIO && (
                          <td>
                            <img
                              src={ViewIcon}
                              alt='viewIcon'
                              title='Detalhar'
                              style={{
                                marginRight: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                const data = {
                                  result_search: result,
                                  fields_search: {
                                    cpf: defaultFormValues.cpf,
                                    name: defaultFormValues.name,
                                    organ: organ,
                                  },
                                  pagination: {
                                    range_page: props.rangePages,
                                    page: props.page,
                                    page_options: props.pageOptions,
                                    total: props.totalFilter,
                                  },
                                  list: props.searchResult,
                                }
                                props.history.push(props.buttonDetailRoute, data)
                              }}
                            />

                            <img
                              src={EditIcon}
                              alt='editIcon'
                              title='Editar'
                              style={{
                                marginRight: '15px',
                                cursor: 'pointer',
                                opacity: result.DATA_CANCELAMENTO !== null ? (new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : '0.4') : '',
                                pointerEvents: result.DATA_CANCELAMENTO !== null ? (new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : 'none') : '',
                              }}
                              onClick={() => {
                                const data = {
                                  result_search: result,
                                  fields_search: {
                                    cpf: defaultFormValues.cpf,
                                    name: defaultFormValues.name,
                                    organ: organ,
                                  },
                                  pagination: {
                                    range_page: props.rangePages,
                                    page: props.page,
                                    page_options: props.pageOptions,
                                    total: props.totalFilter,
                                  },
                                  list: props.searchResult,
                                }
                                props.history.push(props.buttonEditRoute, data)
                              }}
                            />

                            {result.DATA_LIMITE && (
                              <img
                                src={UndoIcon}
                                alt='editIcon'
                                title='Desfazer horário de exceção'
                                style={{
                                  marginRight: '15px',
                                  cursor: 'pointer',
                                  opacity: result.DATA_CANCELAMENTO !== null ? (new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : '0.4') : '',
                                  pointerEvents: result.DATA_CANCELAMENTO !== null ? (new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : 'none') : '',
                                }}
                                onClick={() => {
                                  setRegistro(result)
                                  setModalConfirmVisible(true)
                                }}
                              />
                            )}
                          </td>
                        )}

                        <td> </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <PaginationContainer>
                <nav className='br-pagination' aria-label='Paginação de resultados' data-total='50' data-current='1' data-per-page='20'>
                  <div className='pagination-per-page'>
                    <label style={{ fontWeight: 'normal', marginRight: '15px' }}> Exibir </label>
                    <SelectPagesContainer>
                      <Select
                        options={rangePagesOptions}
                        defaultValue={{ value: '10', label: '10' }}
                        value={props.rangePages}
                        onChange={(value) => {
                          props.setRangePages(value)
                          props.onChangePaginationConfig(defaultFormValues, value, props.page, true)
                        }}
                        styles={SelecPageStyles}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          colors: {
                            ...theme.colors,
                            primary25: '#CCCCCC',
                            primary: '#1351B4',
                          },
                        })}
                        components={{
                          DropdownIndicator,
                          IndicatorSeparator,
                        }}
                      />
                    </SelectPagesContainer>
                  </div>

                  <span className='br-divider d-none d-sm-block mx-3'></span>

                  <div className='pagination-information d-none d-sm-flex'>
                    <span className='current'> {1 + (Number(props.page.value) - 1) * Number(props.rangePages.value)} </span>&ndash;
                    <span className='per-page'> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className='total'> {props.totalFilter} </span>&nbsp;itens
                  </div>
                  <div className='pagination-go-to-page d-none d-sm-flex ml-auto'>
                    <label style={{ fontWeight: 'normal', marginRight: '15px' }}> Página </label>
                    <SelectPagesContainer>
                      <Select
                        options={props.pageOptions}
                        defaultValue={{ value: '1', label: '1' }}
                        value={props.page}
                        onChange={(value) => {
                          props.setPage(value)
                          props.onChangePaginationConfig(defaultFormValues, props.rangePages, value, false)
                        }}
                        styles={SelecPageStyles}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          colors: {
                            ...theme.colors,
                            primary25: '#CCCCCC',
                            primary: '#1351B4',
                          },
                        })}
                        components={{
                          DropdownIndicator,
                          IndicatorSeparator,
                        }}
                      />
                    </SelectPagesContainer>
                  </div>

                  <span className='br-divider d-none d-sm-block mx-3'></span>

                  <div className='pagination-arrows ml-auto ml-sm-0'>
                    <button
                      className='br-button circle'
                      type='button'
                      aria-label='Voltar página'
                      style={{
                        opacity: Number(props.page.value) > 1 ? '' : '0.4',
                        pointerEvents: Number(props.page.value) > 1 ? '' : 'none',
                      }}
                      onClick={() => {
                        const new_page = {
                          value: String(Number(props.page.value) - 1),
                          label: String(Number(props.page.value) - 1),
                        }
                        props.setPage(new_page)
                        props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                      }}>
                      <i className='fas fa-angle-left' aria-hidden='true'>
                        <img src={DropLeftIcon} alt='leftIcon' style={{ marginBottom: '6px' }} />
                      </i>
                    </button>
                    <button
                      className='br-button circle'
                      type='button'
                      aria-label='Avançar página'
                      style={{
                        opacity: Number(props.page.value) < Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value)) ? '' : '0.4',
                        pointerEvents: Number(props.page.value) < Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value)) ? '' : 'none',
                      }}
                      onClick={() => {
                        const new_page = {
                          value: String(Number(props.page.value) + 1),
                          label: String(Number(props.page.value) + 1),
                        }
                        props.setPage(new_page)
                        props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                      }}>
                      <i className='fas fa-angle-right' aria-hidden='true'>
                        <img src={DropRightIcon} alt='rightIcon' style={{ marginBottom: '6px' }} />
                      </i>
                    </button>
                  </div>
                </nav>
              </PaginationContainer>
            </>
          )}

          {props.erroMsg && (
            <span className='feedback warning' role='alert' id='listresult'>
              <i className='fas fa-times-circle' aria-hidden='true'>
                <img src={AlertIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
              </i>
              <label id='listresultlabel'>{props.erroMsg}</label>
            </span>
          )}
        </ContentContainer>
      </GlobalContainer>
    </>
  )
}
