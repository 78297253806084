import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AttendantScheduleDetailsManagement from './AttendantScheduleDetailsManagement'
import ModalWarn from '../../Components/Modal/Warn/index'

export default function AttendantScheduleDetails(props) {
  const location = useLocation()

  const [successMsg, setSuccessMsg] = useState('')
  const [orgaoSchedule, setOrgaoSchedule] = useState('')
  const [loadRegionalManager, setLoadAttendant] = useState(false)
  const [erroMsg, setErroMsg] = useState([])
  const [loadingButton, setLoadingButton] = useState(false)
  const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
  const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
  const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

  const { result_search } = location.state

  const [attendant, setAttendant] = useState({
    cpf: result_search.CPF_USUARIO,
    name: result_search.NOME_USUARIO,
    id_usuario_orgao: result_search.ID_USUARIO_ORGAO,
    organ: { value: result_search.CODIGO_ORGAO, label: result_search.NOME_ORGAO },
    units: [{}],
    dias_semana: result_search.DIAS_SEMANA,
    hora_inicio: result_search.HORA_INICIO,
    hora_fim: result_search.HORA_FIM,
    data_limite: result_search.DATA_LIMITE,
    motivo: result_search.MOTIVO_HORARIO_EXCECAO,
  })

  useEffect(() => {
    async function loadUserUnits() {
      const user = JSON.parse(localStorage.getItem('user'))
      const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: user ? user.token : '',
        }),
        body: JSON.stringify({
          cpf_gestor: user.cpf_atendente,
          id_usuario_orgao: attendant.id_usuario_orgao,
          codigo_orgao: user.codigo_orgao,
        }),
      }
      await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaUnidadesDoUsuarioPorIdUsuOrg`, requestOptions)
        .then(function (response) {
          if (response.ok) {
            response.json().then(function (data) {
              // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
              var unitList = [];
              data.unidades.forEach((unit) => {
                unitList.push({
                  value: unit.CODIGO_UNIDADE,
                  label: unit.NOME_UNIDADE
                });
            });
              setAttendant({...attendant, units: unitList})
            })
          } else if (response.status === 422) {
            response.json().then(function (data) {
              if (!!data.errorCode && !!data.message) {
                // inserir a mensagem: data.message no aviso de erro
                console.log(data.message)
              } else {
                // inserir mensagem genérica
                console.log('Response error in gestao/listaUnidadesDoUsuarioPorIdUsuOrg BackendBalcao')
              }
            })
          } else if (response.status === 403) {
            response.json().then(function (data) {
              setModalInvalidSessionErrorTitle(data.title ? data.title : null)
              setModalInvalidSessionErrorMsg(data.message ? data.message : null)
              setModalInvalidSessionError(true)
            })
          } else {
            // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
            //setLoading(false);
            //setModalError(true);
            console.log('Request error in gestao/listaUnidadesDoUsuarioPorIdUsuOrg BackendBalcao')
          }
        })
        .catch(function (err) {
          console.error('Failed retrieving information', err)
        })
    }
    async function loadDefaultOrganSchedule() {
      const user = JSON.parse(localStorage.getItem('user'))
      const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: user ? user.token : '',
        }),
        body: JSON.stringify({
          cpf_gestor: user.cpf_atendente,
          codigo_orgao: user.codigo_orgao,
        }),
      }
      await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/consultaHorarioOrgao`, requestOptions)
        .then(function (response) {
          if (response.ok) {
            response.json().then(function (data) {
              // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
              setOrgaoSchedule({
                codigo_orgao: data.CODIGO_ORGAO,
                nome_orgao: data.NOME_ORGAO,
                dias_semana: data.DIAS_SEMANA,
                hora_inicio: data.HORA_INICIO,
                hora_fim: data.HORA_FIM,
              })
            })
          } else if (response.status === 422) {
            response.json().then(function (data) {
              if (!!data.errorCode && !!data.message) {
                // inserir a mensagem: data.message no aviso de erro
                console.log(data.message)
              } else {
                // inserir mensagem genérica
                console.log('Response error in gestao/detalhaGestorRegional BackendBalcao')
              }
            })
          } else if (response.status === 403) {
            response.json().then(function (data) {
              setModalInvalidSessionErrorTitle(data.title ? data.title : null)
              setModalInvalidSessionErrorMsg(data.message ? data.message : null)
              setModalInvalidSessionError(true)
            })
          } else {
            // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
            //setLoading(false);
            //setModalError(true);
            console.log('Request error in gestao/detalhaGestorRegional BackendBalcao')
          }
        })
        .catch(function (err) {
          console.error('Failed retrieving information', err)
        })
    }
    loadUserUnits()
    loadDefaultOrganSchedule()
    setLoadAttendant(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function confirmUpdate() {
    setLoadingButton(true)
    const user = JSON.parse(localStorage.getItem('user'))

    const requestOptions = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: user ? user.token : '',
      }),

      body: JSON.stringify({
        cpf_gestor: user.cpf_atendente,
        codigo_orgao: attendant.organ.value,
        codigo_unidade: user.codigo_unidade,
        id_usuario_orgao: attendant.id_usuario_orgao,
        cpf_usuario_atendente: attendant.cpf,
        dias_semana: attendant.dias_semana,
        hora_inicio: attendant.hora_inicio,
        hora_fim: attendant.hora_fim,
        data_limite: attendant.data_limite,
        motivo_horario_excecao: attendant.motivo,
      }),
    }

    await fetch(`${process.env.REACT_APP_API_URL}/gestao/horario/editaHorarioAtendente`, requestOptions)
      .then(function (response) {
        if (response.ok) {
          response.json().then(function (data) {
            setSuccessMsg(data.message)
            setErroMsg(undefined)
          })
        } else {
          response.json().then(function (data) {
            setErroMsg(data.messages || data.message)
            console.log('Error request to /gestao/horario/editaHorarioAtendente', data)
          })
        }
      })
      .catch(function (err) {
        console.error('Failed retrieving information', err)
      })
    setLoadingButton(false)
    window.scrollTo(0,0)
  }

  return (
    <>
      <ModalWarn
          id='modalWarnalertInvalidSession'
          appear={modalInvalidSessionError}
          title = { modalInvalidSessionErrorTitle }
          text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
          textButton2='Ir para tela inicial'
          actionButton2={() => { 
              document.getElementById("btnLogout").click();
          }}
      />
      <AttendantScheduleDetailsManagement
        pageTitle='Horário de exceção para atendente'
        attendant={attendant}
        setAttendant={setAttendant}
        loadOperator={loadRegionalManager}
        buttonBackRoute='/horarios'
        buttonEditRoute='/editarHorarioOrgao'
        confirmUpdate={confirmUpdate}
        orgaoSchedule={orgaoSchedule}
        history={props.history}
        erroMsg={erroMsg}
        fromSuccessMsg={successMsg}
        loadingButton={loadingButton}
      />
    </>
  )
}
