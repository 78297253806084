import React, { useState, useEffect } from 'react';

import EditOperator from '../../Components/EditOperator';
import ModalWarn from '../../Components/Modal/Warn/index'

export default function EditRegionalManager(props) {
    const [ regionalManager, setRegionalManager ] = useState({
        cpf: '',
        name: '',
        dt_canc_exp: '' //"2021-07-08"
    });

    const [ organ, setOrgan ] = useState({ value: "", label: "" });
    const [ loadRegionalManager, setLoadRegionalManager ] = useState(false);
    const [ hasAssociatedAttendants, setHasAssociatedAttendants] = useState(false);
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        async function loadRegionalManagerDetails() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                }),
            };
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/detalhaGestorRegional`, requestOptions).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!
                        const organ={
                            value: data.CODIGO_ORGAO,
                            label: data.NOME_ORGAO
                        }
                        let unidades = [];
                        data.UNIDADES_VINCULADAS && data.UNIDADES_VINCULADAS.forEach((unidade) => {
                            unidades.push({
                                value: unidade.CODIGO_UNIDADE,
                                label: unidade.NOME_UNIDADE
                            })
                        })

                        setRegionalManager({
                            id_usuario_orgao: data.ID_USUARIO_ORGAO, 
                            cpf: props.location.state.result_search.CPF_USUARIO, 
                            name: props.location.state.result_search.NOME_USUARIO, 
                            organ: organ, 
                            dt_inc: data.DATA_INCLUSAO, 
                            dt_canc_exp: data.DATA_CANCELAMENTO,
                            unidades_vinculadas: unidades,
                        });
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            // inserir a mensagem: data.message no aviso de erro
                            console.log(data.message);
                        } else {
                            // inserir mensagem genérica
                            console.log('Response error in gestao/detalhaGestorRegional BackendBalcao');
                        }
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setModalInvalidSessionError(true)
                    })
                } else {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    //setLoading(false);
                    //setModalError(true);
                    console.log('Request error in gestao/detalhaGestorRegional BackendBalcao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }
        
        async function loadAssociatedAttendants(cpf_usuario_cadastrador) {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_usuario_cadastrador: cpf_usuario_cadastrador,
                    pagina_atual: 1,
                    qtd_registros: 10
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaAtendentesAssociadosAoGestor`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        setHasAssociatedAttendants(data.total !== "0");
                    });
                } else {
                    console.log('Error request to /gestao/listaAtendentesAssociadosAoGestor');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }
       
        if (!loadRegionalManager && props.location.state !== undefined) {
            setOrgan({
                value: props.location.state.result_search.CODIGO_ORGAO,
                label: props.location.state.result_search.NOME_ORGAO,
            });
            loadRegionalManagerDetails();
            loadAssociatedAttendants(props.location.state.result_search.CPF_USUARIO);
            setLoadRegionalManager(true);
        }
    }, [props.location.state, loadRegionalManager]);

    const [ loadingEdit, setLoadingEdit ] = useState(false);

    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");

    const onSubmitEdit = async () => {
        
        setLoadingEdit(true);

        const requestOptions = {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                cpf_gestor_regional: regionalManager.cpf,
                nome_gestor_regional: regionalManager.name,
                codigo_orgao: organ.value,
                data_cancelamento: regionalManager.dt_canc_exp,
                unidades_vinculadas: regionalManager.unidades_vinculadas
            }),
        };
        
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/editaGestorRegional`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    const regionalManager_added = {
                        from: "editRegionalManager",
                        id_usuario_orgao: props.location.state.result_search.ID_USUARIO_ORGAO,
                        cpf: data_request.objGestorRegional.cpf_gestor_regional,
                        name: data_request.objGestorRegional.nome_gestor_regional,
                        organ: organ,
                        dt_inc: data_request.objGestorRegional.data_inclusao,
                        dt_canc_exp: data_request.objGestorRegional.data_cancelamento,
                        unidades_vinculadas: data_request.objGestorRegional.unidades_vinculadas
                    }
                    setLoadingEdit(false);
                    
                    //console.log(regionalManager_aEditd);
                    props.history.push('mostrarGestorRegional', regionalManager_added);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/editaGestorRegional BackendBalcao');
                    }

                    setLoadingEdit(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingEdit(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingEdit(false);
                console.log('Error request to /gestao/editaGestorRegional');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <EditOperator
                pageTitle='Editar gestor regional'
                operator={regionalManager}
                setOperator={setRegionalManager}
                organ={organ}
                setOrgan={setOrgan}
                loadOperator={loadRegionalManager}
                loadingEdit={loadingEdit}
                setLoadingEdit={setLoadingEdit}
                modalError={modalError}
                setModalError={setModalError}
                messageError={messageError}
                setMessageError={setMessageError}
                onSubmitEdit={onSubmitEdit}
                history={props.history}
                location={props.location}
                buttonCancelRoute='/gerenciarGestoresRegionais'
                hasAssociated={hasAssociatedAttendants}
            />
        </>
    );
}