import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom'
import {
    GlobalContainer,
    ContentContainer,
    TitleContainer,
    Title,
    Subtitle,
    Line,
    SearchContainer,
    SelectContainer,
    SelectPagesContainer,
    InputContainer,
    SearchButtonsContainer,
    PaginationContainer,
} from './styles';
import Select, { components } from 'react-select';
import ModalCancel from '../../Components/Modal/Cancel/index';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import DropRightIcon from '../../assets/drop_right_icon.png';
import DropLeftIcon from '../../assets/drop_left_icon.png';
import FailIcon from '../../assets/fail_icon.png';
import ViewIcon from '../../assets/eye_icon.png';
import EditIcon from '../../assets/edit_icon.png';
import TrashIcon from '../../assets/trash2_icon.png';
import FilterIcon from '../../assets/filter_icon.png';
import ReplaceIcon from '../../assets/replace.png';
import AlertIcon from '../../assets/alert_icon2.png';
import { cpfMask, formatDate, isCpfValid } from '../../utils';
import { profile } from '../../constants';
import ModalWarn from '../../Components/Modal/Warn/index';

export default function Management(props) {
    const location = useLocation()
    const [defaultFormValues, setDefaultFormValues] = useState({
        cpf: "",
        name: "",
        organ: "",
    });

    const [organ, setOrgan] = useState(
        {
            value: JSON.parse(localStorage.getItem('user')).codigo_orgao ? JSON.parse(localStorage.getItem('user')).codigo_orgao : "0",
            label: JSON.parse(localStorage.getItem('user')).orgao ? JSON.parse(localStorage.getItem('user')).orgao : "Todos os órgãos"
        }
    );

    const [ unidade, setUnidade ] = useState(
        { 
            value: JSON.parse(localStorage.getItem('user')).codigo_unidade ? JSON.parse(localStorage.getItem('user')).codigo_unidade : "0", 
            label: JSON.parse(localStorage.getItem('user')).unidade ? JSON.parse(localStorage.getItem('user')).unidade : "Todas as unidades"
        }
    );

    useEffect(() => {
            setDefaultFormValues({...defaultFormValues, organ: organ.value, unidade: unidade.value});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { handleSubmit, errors, control, reset } = useForm({ defaultFormValues });

    const [rangePagesOptions] = useState([
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
    ]);

    // ESTILIZACAO PARA O SELECT ======================================================
    const SelecStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: state.isFocused ? 2.5 : 1,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 6,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: 13.5,
            fontWeight: 355,
            marginLeft: 7,
            fontStyle: 'italic',
        }),
    }
    const SelecPageStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: 0,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 15,
        }),
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen 
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon"/>
                }
            </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => {
        return <span style={{}} {...innerProps} />;
    };
    // ================================================================================ 

    const [modalCancel, setModalCancel] = useState(false);

    const perfil = JSON.parse(localStorage.getItem("user")).perfil;
    const [showInvalidErroCpf, setShowInvalidErroCpf] = useState(false);

    return (
        <>
            <ModalCancel
                id="modalcancelalert"
                appear={modalCancel}
                text={"Deseja cancelar a pesquisa e retornar para página principal?"}
                actionButton1={() => setModalCancel(false)}
                actionButton2={() => props.history.push("/pesquisarcidadao")}
            />
            <ModalWarn
                id="modalconfirmalert"
                appear={props.modalError}
                text = {
                    'Houve algum problema no servidor. Aguarde um momento e tente novamente.'
                }
                textButton2='Ok'
                actionButton2={() => props.setModalError(false)}
            />


            <GlobalContainer>
                <ContentContainer>
                    <TitleContainer>
                        <Title>{props.pageTitle}</Title>
                        {( (perfil === profile.gestorSistema && location.pathname === '/gerenciarGestoresOrgao')
                         ||(perfil === profile.gestorOrgao && location.pathname === '/gerenciarGestoresRegionais')
                         || (perfil === profile.gestorRegional && location.pathname === '/gerenciaratendentes')) && (
                            <button className="br-button primary" onClick={() => props.history.push(props.buttonRegistrationRoute)}>
                                {props.buttonRegistrationTitle}
                            </button>
                        )}
                    </TitleContainer>

                    <Subtitle>
                        <img
                            src={FilterIcon}
                            alt="filterIcon"
                            style={{ "marginBottom": "7px", "marginRight": "7px" }}
                        />
                        <label> Filtro </label>
                        <Line />
                    </Subtitle>

                    <form onSubmit={handleSubmit(() => {if (defaultFormValues.cpf && defaultFormValues.cpf.trim().length > 0 && !isCpfValid(defaultFormValues.cpf)) {setShowInvalidErroCpf(true);} else {props.onSubmitSearch(defaultFormValues);} })}>
                        <SearchContainer>
                            <InputContainer>
                                <div className="br-input">
                                    <label htmlFor="cpf"> CPF </label>
                                    <input
                                        id="cpf"
                                        name="cpf"
                                        placeholder="Digite somente números"
                                        type="text"
                                        maxLength="14"
                                        defaultValue={cpfMask(defaultFormValues.cpf)}
                                        value={cpfMask(defaultFormValues.cpf)}
                                        onInput={e => { 
                                            setDefaultFormValues({ ...defaultFormValues, cpf: e.target.value })
                                            setShowInvalidErroCpf(false); 
                                            props.setSearchResult([]);
                                            props.setErroMsg(undefined);
                                        }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                    />
                                </div>
                                {showInvalidErroCpf &&
                                    <span className="feedback danger" role="alert">
                                        <i className="fas fa-times-circle" aria-hidden="true">
                                            <img
                                                src={FailIcon}
                                                style={{ paddingBottom: '5px' }}
                                                alt="failicon"
                                            />
                                        </i>
                                        CPF Inválido
                                    </span>}
                            </InputContainer>

                            <InputContainer>
                                <div className="br-input">
                                    <label htmlFor="name"> Nome </label>
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder={props.fieldNamePlaceholder}
                                        defaultValue={defaultFormValues.name}
                                        onChange={(event) => {
                                            const { value } = event.target
                                            setDefaultFormValues({ ...defaultFormValues, name: value })
                                            props.setSearchResult([]);
                                            props.setErroMsg(undefined);
                                        }}
                                    />
                                </div>
                            </InputContainer>
                            {perfil === profile.gestorSistema &&
                                <SelectContainer>                                
                                    <label> Órgão </label>
                                    <Controller
                                        name="organ"
                                        control={control}
                                        render={
                                            (e) => (
                                                <Select
                                                    onChange={(value) => {
                                                        e.onChange(value);
                                                        setOrgan({ value: value.value, label: value.label });
                                                        setDefaultFormValues({ ...defaultFormValues, organ: value.value });
                                                        reset();
                                                        props.setSearchResult([]);
                                                        props.setErroMsg(undefined);
                                                    }}
                                                    options={props.organOptions}
                                                    value={organ.value === "" ? "" : organ}
                                                    placeholder="Selecione o órgão"
                                                    styles={SelecStyles}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 6,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#CCCCCC',
                                                            primary: '#1351B4'
                                                        },
                                                    })}
                                                    components={
                                                        { DropdownIndicator, IndicatorSeparator }
                                                    }
                                                />
                                            )
                                        }
                                        rules={{
                                            //required: { value: true, message: "Selecione um órgão." }
                                            validate: () => organ.value !== "" ? true : "Selecione um órgão."
                                        }}
                                    />
                                    
                                    {errors?.organ &&
                                        <span className="feedback danger" role="alert" id="organresult">
                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                <img
                                                    src={FailIcon}
                                                    style={{ paddingBottom: '5px' }}
                                                    alt="failicon"
                                                />
                                            </i>
                                            {errors.organ.message}
                                        </span>}
                                </SelectContainer>
                            }
                            {(perfil !== profile.gestorSistema) &&
                                <SelectContainer>
                                    <label> Unidade do Órgão </label>
                                    {(perfil === profile.gestorRegional) &&  <label> {unidade.label} </label>}
                                    {(perfil !== profile.gestorRegional) &&
                                        <Controller
                                            name="unidade"
                                            control={control}
                                            render={
                                                (e) => (
                                                    <Select
                                                        onChange={(value) => {
                                                            e.onChange(value);
                                                            setUnidade({ value: value.value, label: value.label });
                                                            setDefaultFormValues({ ...defaultFormValues, unidade: value.value });
                                                            reset();
                                                            props.setSearchResult([]);
                                                            props.setErroMsg(undefined);
                                                        }}
                                                        options={props.unidadeOptions}
                                                        value={unidade.value === "" ? "" : unidade}
                                                        placeholder="Selecione a unidade"
                                                        styles={SelecStyles}
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 6,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#CCCCCC',
                                                                primary: '#1351B4'
                                                            },
                                                        })}
                                                        components={
                                                            { DropdownIndicator, IndicatorSeparator }
                                                        }
                                                    />
                                                )
                                            }
                                            rules={{
                                                //required: { value: true, message: "Selecione um órgão." }
                                                validate: () => organ.value !== "" ? true : "Selecione uma unidade."
                                            }}
                                        />
                                    }
                                    {errors?.unidade &&
                                        <span className="feedback danger" role="alert" id="organresult">
                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                <img
                                                    src={FailIcon}
                                                    style={{ paddingBottom: '5px' }}
                                                    alt="failicon"
                                                />
                                            </i>
                                            {errors.unidade.message}
                                        </span>}
                                </SelectContainer>
                            }                            
                        </SearchContainer>

                        <SearchButtonsContainer>
                            <button className="br-button secundary" type="button"
                                onClick={() => setModalCancel(true)}>
                                Cancelar
                            </button>
                            <button className="br-button secondary" type="button"
                                onClick={() => {
                                    setDefaultFormValues({...defaultFormValues, cpf: "", name: "", unidade: '0'})
                                    if (perfil === profile.atendente) {
                                        setOrgan({ value: "0", label: "Selecione o órgão" });
                                        setDefaultFormValues({ ...defaultFormValues, organ: "0"});
                                    }
                                    if (perfil !== profile.gestorRegional) {
                                        setUnidade({ value: "0", label: "Todas as unidades" });
                                        setDefaultFormValues({ ...defaultFormValues, unidade: '0' })
                                    }
                                    props.setSearchResult([]);
                                    setDefaultFormValues({ ...defaultFormValues, cpf: '', name: '', unidade: '0' })
                                    document.getElementById('name').value = "";
                                    document.getElementById('cpf').value = "";
                                    props.setErroMsg(undefined);
                                    reset()
                                }}>
                                Limpar Filtro
                            </button>
                            {!props.buttonNextLoading ?
                                <button className="br-button primary" type="submit">
                                    Pesquisar
                                </button> 
                            :
                                <button className="br-button primary loading" type="button">
                                    Pesquisar
                                </button>
                            }
                        </SearchButtonsContainer>

                        <Subtitle>
                            <Line />
                        </Subtitle>
                    </form>

                    {!!props.searchResult.length && <>
                        <Subtitle>
                            <label>{props.tableResultListTitle}</label>
                        </Subtitle>
                        <div className="br-table" data-search="data-search" data-selection="data-selection">
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col0" style={{ 'color': '#1351B4' }}></th>
                                        <th scope="col1" style={{ 'color': '#1351B4' }}> Nome completo </th>
                                        <th scope="col2" style={{ 'color': '#1351B4', 'width': '130px'}}> CPF </th>
                                        {location.pathname !== '/gerenciaratendentes' && (perfil === profile.gestorSistema) &&
                                            <th scope="col3" style={{ 'color': '#1351B4' }}> Órgão </th>
                                        }
                                        { (perfil !== profile.gestorSistema) && <th scope="col3" style={{ 'color': '#1351B4' }}> Unidades </th>}
                                        {location.pathname !== '/gerenciaratendentes' &&
                                            <th scope="col4" style={{ 'color': '#1351B4' }}> Data de Cadastro </th>
                                        }
                                        {location.pathname !== '/gerenciaratendentes' &&
                                            <th scope="col5" style={{ 'color': '#1351B4' }}> Data de Expiração </th>
                                        }
                                        <th scope="col8" style={{ 'color': '#1351B4' }}> Ações </th>
                                        <th scope="col99" style={{ 'color': '#1351B4' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.searchResult.map(result => (
                                        <tr key={result.ID_USUARIO_ORGAO}>
                                            <td> </td>
                                            <td> {result.NOME_USUARIO} </td>
                                            <td> { cpfMask(result.CPF_USUARIO) }</td>
                                            {location.pathname !== '/gerenciaratendentes' && (perfil === profile.gestorSistema) &&
                                                <td> {result.NOME_ORGAO} </td>
                                            }
                                            {(perfil !== profile.gestorSistema) && 
                                                <td>
                                                    {result?.unidades?.length > 0 && (
                                                            result.unidades.map((unidade, idx) => (
                                                            <React.Fragment key={idx}>
                                                                {unidade.NOME_UNIDADE}
                                                                <br />
                                                            </React.Fragment>
                                                         ))
                                                    )}
                                                </td>
                                            }
                                            {location.pathname !== '/gerenciaratendentes' &&
                                                <td> { formatDate(result.DATA_INCLUSAO) } </td>
                                            }
                                            {location.pathname !== '/gerenciaratendentes' &&
                                                <td> {result.DATA_CANCELAMENTO === null ? "-" : formatDate(result.DATA_CANCELAMENTO) } </td>
                                            }
                                            {(result.DATA_BLOQUEIO) &&
                                                <td style={{ 'color': '#b41313' }}>BLOQUEADO</td>
                                            }
                                            {(!result.DATA_BLOQUEIO) &&
                                                <td>
                                                    <img
                                                        src={ViewIcon}
                                                        alt="viewIcon"
                                                        title="Detalhar"
                                                        style={{
                                                            'marginRight': '15px',
                                                            'cursor': 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            const data = {
                                                                result_search: result,
                                                                fields_search: {
                                                                    cpf: defaultFormValues.cpf,
                                                                    name: defaultFormValues.name,
                                                                    organ: organ
                                                                },
                                                                pagination: {
                                                                    range_page: props.rangePages,
                                                                    page: props.page,
                                                                    page_options: props.pageOptions,
                                                                    total: props.totalFilter
                                                                },
                                                                list: props.searchResult
                                                            }
                                                            props.history.push(props.buttonDetailRoute, data);
                                                        }}
                                                    />
                                                    {!(perfil === profile.gestorOrgao && location.pathname === '/gerenciaratendentes') &&
                                                        <img
                                                            src={EditIcon}
                                                            alt="editIcon"
                                                            title="Editar"
                                                            style={{
                                                                'marginRight': '15px',
                                                                'cursor': 'pointer',
                                                                'opacity': result.DATA_CANCELAMENTO !== null ? new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : '0.4' : '',
                                                                'pointerEvents': result.DATA_CANCELAMENTO !== null ? new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : 'none' : ''
                                                            }}
                                                            onClick={() => {
                                                                const data = {
                                                                    result_search: result,
                                                                    fields_search: {
                                                                        cpf: defaultFormValues.cpf,
                                                                        name: defaultFormValues.name,
                                                                        organ: organ
                                                                    },
                                                                    pagination: {
                                                                        range_page: props.rangePages,
                                                                        page: props.page,
                                                                        page_options: props.pageOptions,
                                                                        total: props.totalFilter
                                                                    },
                                                                    list: props.searchResult
                                                                }
                                                                props.history.push(props.buttonEditRoute, data);
                                                            }}
                                                        />
                                                    }
                                                    {!(perfil === profile.gestorOrgao && location.pathname === '/gerenciaratendentes') &&
                                                        <img
                                                            src={TrashIcon}
                                                            alt="trashIcon"
                                                            title="Desvincular"
                                                            style={{
                                                                'cursor': 'pointer',
                                                                'opacity': result.DATA_CANCELAMENTO !== null ? new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : '0.4' : '',
                                                                'pointerEvents': result.DATA_CANCELAMENTO !== null ? new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : 'none' : ''
                                                            }}
                                                            onClick={() => {
                                                                const data = {
                                                                    result_search: result,
                                                                    fields_search: {
                                                                        cpf: defaultFormValues.cpf,
                                                                        name: defaultFormValues.name,
                                                                        organ: organ
                                                                    },
                                                                    pagination: {
                                                                        range_page: props.rangePages,
                                                                        page: props.page,
                                                                        page_options: props.pageOptions,
                                                                        total: props.totalFilter
                                                                    },
                                                                    list: props.searchResult
                                                                }
                                                                props.history.push(props.buttonCancelRoute, data);
                                                            }}
                                                        />
                                                    }
                                                    {(perfil === profile.gestorOrgao && !result.DATA_BLOQUEIO  && location.pathname === '/gerenciaratendentes') &&
                                                    <img
                                                        src={ReplaceIcon}
                                                        alt="trashIcon"
                                                        title="Substituir Gestor Regional"
                                                        style={{
                                                            width: '20px',
                                                            'cursor': 'pointer',
                                                            'opacity': result.DATA_CANCELAMENTO !== null ? new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : '0.4' : '',
                                                            'pointerEvents': result.DATA_CANCELAMENTO !== null ? new Date(result.DATA_CANCELAMENTO) >= Date.now() ? '' : 'none' : ''
                                                        }}
                                                        onClick={() => {
                                                            const data = {
                                                                result_search: result,
                                                                fields_search: {
                                                                    cpf: defaultFormValues.cpf,
                                                                    name: defaultFormValues.name,
                                                                    organ: organ
                                                                },
                                                                pagination: {
                                                                    range_page: props.rangePages,
                                                                    page: props.page,
                                                                    page_options: props.pageOptions,
                                                                    total: props.totalFilter
                                                                },
                                                                list: props.searchResult
                                                            }
                                                            props.history.push('informarGestorRegionalSubstituto', data)
                                                        }}
                                                    />
                                                    }      
                                                    </td>
                                                    }
                                                                                          
                                            <td> </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <PaginationContainer>
                            <nav className="br-pagination" aria-label="Paginação de resultados" data-total="50" data-current="1" data-per-page="20">
                                <div className="pagination-per-page">
                                    <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Exibir </label>
                                    <SelectPagesContainer>
                                        <Select
                                            options={rangePagesOptions}
                                            defaultValue={{ value: "10", label: "10" }}
                                            value={props.rangePages}
                                            onChange={value => {
                                                props.setRangePages(value)
                                                props.onChangePaginationConfig(defaultFormValues, value, props.page, true)
                                            }}
                                            styles={SelecPageStyles}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 6,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#CCCCCC',
                                                    primary: '#1351B4'
                                                },
                                            })}
                                            components={
                                                {
                                                    DropdownIndicator,
                                                    IndicatorSeparator
                                                }
                                            }
                                        />
                                    </SelectPagesContainer>
                                </div>

                                <span className="br-divider d-none d-sm-block mx-3"></span>

                                <div className="pagination-information d-none d-sm-flex">
                                    <span className="current"> {1 + ((Number(props.page.value) - 1) * Number(props.rangePages.value))} </span>&ndash;<span className="per-page"> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className="total"> {props.totalFilter} </span>&nbsp;itens
                                </div>
                                <div className="pagination-go-to-page d-none d-sm-flex ml-auto">
                                    <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Página </label>
                                    <SelectPagesContainer>
                                        <Select
                                            options={props.pageOptions}
                                            defaultValue={{ value: "1", label: "1" }}
                                            value={props.page}
                                            onChange={value => {
                                                props.setPage(value)
                                                props.onChangePaginationConfig(defaultFormValues, props.rangePages, value, false)
                                            }}
                                            styles={SelecPageStyles}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 6,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#CCCCCC',
                                                    primary: '#1351B4'
                                                },
                                            })}
                                            components={
                                                {
                                                    DropdownIndicator,
                                                    IndicatorSeparator
                                                }
                                            }
                                        />
                                    </SelectPagesContainer>
                                </div>

                                <span className="br-divider d-none d-sm-block mx-3"></span>

                                <div className="pagination-arrows ml-auto ml-sm-0">
                                    <button className="br-button circle" type="button" aria-label="Voltar página"
                                        style={{
                                            'opacity': Number(props.page.value) > 1 ? '' : '0.4',
                                            'pointerEvents': Number(props.page.value) > 1 ? '' : 'none'
                                        }}
                                        onClick={() => {
                                            const new_page = {
                                                value: String(Number(props.page.value) - 1),
                                                label: String(Number(props.page.value) - 1)
                                            }
                                            props.setPage(new_page)
                                            props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                                        }}
                                    >
                                        <i className="fas fa-angle-left" aria-hidden="true">
                                            <img
                                                src={DropLeftIcon}
                                                alt="leftIcon"
                                                style={{ "marginBottom": "6px" }}
                                            />
                                        </i>
                                    </button>
                                    <button className="br-button circle" type="button" aria-label="Avançar página"
                                        style={{
                                            'opacity': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : '0.4',
                                            'pointerEvents': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : 'none'
                                        }}
                                        onClick={() => {
                                            const new_page = {
                                                value: String(Number(props.page.value) + 1),
                                                label: String(Number(props.page.value) + 1)
                                            }
                                            props.setPage(new_page)
                                            props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                                        }}
                                    >
                                        <i className="fas fa-angle-right" aria-hidden="true">
                                            <img
                                                src={DropRightIcon}
                                                alt="rightIcon"
                                                style={{ "marginBottom": "6px" }}
                                            />
                                        </i>
                                    </button>
                                </div>
                            </nav>
                        </PaginationContainer>
                    </>}

                    {
                        props.erroMsg &&
                            <span className="feedback warning" role="alert" id="listresult">
                                <i className="fas fa-times-circle" aria-hidden="true">
                                    <img
                                        src={AlertIcon}
                                        style={{ paddingBottom: '5px' }}
                                        alt="failicon"
                                    />
                                </i>
                                <label id="listresultlabel">{props.erroMsg}</label>
                            </span>
                    }
                </ContentContainer>
            </GlobalContainer>
        </>
    );
}