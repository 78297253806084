import React, { useEffect, useState } from 'react';

import ShowOperator from '../../Components/ShowOperator';

export default function ShowRegionalManager(props) {
    const [ regionalManager, setRegionalManager ] = useState({
        from: '',
        id_usuario_orgao: '',
        cpf: '',
        name: '',
        organ: '',
        dt_inc: '',
        dt_canc_exp: ''
    });

    const [ loadRegionalManager, setLoadRegionalManager ] = useState(false);
    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    
    useEffect(() => {
        if (!loadRegionalManager && props.location.state !== undefined) {
            setRegionalManager({
                from: props.location.state.from,
                id_usuario_orgao: props.location.state.id_usuario_orgao,
                cpf: props.location.state.cpf,
                name: props.location.state.name,
                organ: props.location.state.organ,
                unidade: props.location.state.unidade,
                dt_inc: props.location.state.dt_inc,
                dt_canc_exp: props.location.state.dt_canc_exp,
                unidades_vinculadas: props.location.state.unidades_vinculadas
            });
            setLoadRegionalManager(true);
        }
    }, [props.location.state, loadRegionalManager]);

    return (
        <ShowOperator
            pageTitle={regionalManager.from === "addRegionalManager" ? 'Gestor regional cadastrado': 'Gestor regional atualizado'}
            operator={regionalManager} 
            setOperator={setRegionalManager}
            loadOperator={loadRegionalManager}
            fromSuccessMsg={regionalManager.from === "addRegionalManager" ? 'Gestor regional cadastrado com sucesso.' : 'Gestor regional atualizado com sucesso.'}
            buttonBackRoute='/gerenciarGestoresRegionais'
            buttonEditRoute='/editarGestorRegional'
            buttonRegistrationTitle='+ Cadastrar gestor regional'
            buttonRegistrationRoute='/consultaGestorRegional'
            history={props.history}
            rangePages={rangePages}
            setRangePages={setRangePages}
        />
    );
}