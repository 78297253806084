import styled from 'styled-components';

export const Title = styled.h1 `
    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
    }
`;

export const GlobalContainer = styled.div `

    @media (max-width: 1000px) {
        div {
            display: grid;
            margin: 0px auto;
        }
    }

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const ContentContainer = styled.div `
    margin: 0px auto;
    max-width: 1000px;
`;

export const ContentContainer3 = styled.div`
  display: flex;
  justify-content: center;
  max-width: 790;
  margin: 0px auto;
`

export const ContentContainer4 = styled.div`
  margin: 20px auto;
  max-width: 790px;
`

export const ContentContainerTable = styled.div`
  width: 790px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    width: 410px;
  }
`

export const TitleContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
    justify-content: space-between;
`;

export const Subtitle = styled.div `
    padding: 0px 0px 0px;
    margin-bottom: 15px;
    
    label {
       font-size: 18px;
    }
`;

export const Line = styled.div `
    margin-top: 5px;
    border-bottom: 1px solid #cccccc;
`;

export const SearchContainer = styled.div `
    padding: 0px 0px 40px;
    display: flex;

    //border: 1px solid;
    //border-color: blue;
`;

export const InputContainer = styled.div `
    display: grid;
    margin-right: 30px;
    min-height: 38px;

    //border: 1px solid;
    //border-color: red;
`;

export const SelectContainer = styled.div `
    width: 200px;
    height: 38px;
    display: grid;

    //border: 1px solid;
    //border-color: red;
`;

export const SelectPagesContainer = styled.div `
    width: 55px;
    height: 64px;
    display: grid;

    //border: 1px solid;
    //border-color: red;
`;

export const SearchButtonsContainer = styled.div `
    padding: 0px 0px 10px;
    text-align: right;

    button:nth-child(2){ 
        margin-left: 10px;
    }

    button:nth-child(3){ 
        margin-left: 10px;
    }
`;

export const PaginationContainer = styled.div `
    margin-top: 20px;
    padding: 0px 30px;

    //border: 1px solid;
    //border-color: red;
`;

export const ContentContainerDown = styled.div`
  display: flex;
  justify-content: center;
  max-width: 790;
  margin: 20px auto 0px;
`

export const ContentContainerButtons = styled.div`
  padding: 0px 0px 20px;
  width: 1040px;
  text-align: right;

  button:nth-child(2) {
    /*-pegando o segundo button desta div-*/
    margin-left: 10px;
  }

  button:nth-child(3) {
    margin-left: 10px;
  }
`