import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import DropLeftIcon from '../../assets/drop_left_icon.png';
import DropRightIcon from '../../assets/drop_right_icon.png';
import TrashIcon from '../../assets/trash2_icon.png';
import {
    GlobalContainer,
    ContentContainer,
    ContentContainerBlock,
    ContentContainer1,
    ContentContainer4,
    ContentContainer11,
    ContentContainer12,
    ContentContainer13,
    ContentContainer14,
    ContentContainer15,
    Title,
    TitleContainer,
    ContentContainerButtons,
    SelectContainer,
    PaginationContainer,
    SelectPagesContainer,
    Mandatory
} from './styles';
import Select, { components } from 'react-select';
import ModalCancel from '../Modal/Cancel/index';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import FailIcon from '../../assets/fail_icon.png';
import { formatarCEP, cpfMask, isCpfValid, formatDate, removeInputMask } from '../../utils';
import ModalAddGestorRegional from '../Modal/AddGestorRegional';

export default function UnitElement(props) {
    const [rangePagesOptions] = useState([
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" }
    ]);
    const [defaultFormValues, setDefaultFormValues] = useState({
        organCode: "",
        unitCode: "",
        unitName: "",
        zipcode: "",
        state: "",
        street: "",
        number: "" ,   
        addressComplement: "",
        neighborhood: "",
        city: ""
    });
    const [cpf, setCpf] = useState("");
    const { handleSubmit } = useForm({ defaultFormValues });
    const [showErroUnitName, setShowErroUnitName] = useState(false);
    const [showErroZipcode, setShowErroZipcode] = useState(false);
    const [showInvalidErroZipcode, setShowInvalidErroZipcode] = useState(false);
    const [showErroStreet, setShowErroStreet] = useState(false);
    const [showErroState, setShowErroState] = useState(false);
    const [showErroNeighborhood, setShowErroNeighborhood] = useState(false);
    const [showErroCity, setShowErroCity] = useState(false);
    const [showErroCpf, setShowErroCpf] = useState(false);
    const [showInvalidErroCpf, setShowInvalidErroCpf] = useState(false);
    const SelecPageStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: 0,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 15,
        }),
    }
    useEffect(() => {
            setDefaultFormValues(props.unit);         
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.unit])

    // ESTILIZACAO PARA O SELECT ======================================================
    const SelecStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: state.isFocused ? 2.5 : 1,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 6,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: 13.5,
            fontWeight: 355,
            marginLeft: 7,
            fontStyle: 'italic',
        }),
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon" />
                }
            </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => <span style={{}} {...innerProps} />;

    const [isModalCancelVisible, setModalCancelVisible] = useState(false);

    const isZipCodeValid = (zipCode) => {
        const normalizedZipCode = zipCode && zipCode.replace(/\D/g, '')
        if (!normalizedZipCode || !/^[0-9]{8}$/.test(normalizedZipCode)) {
            return false
        }
        return true
    }

    const isAllFieldsValidated = () => {
        if (!defaultFormValues?.unitName?.trim()) {
            setShowErroUnitName(true);
            return false;
        }
        if (!(defaultFormValues?.zipcode && defaultFormValues?.zipcode?.trim())) {
            setShowErroZipcode(true);
            return false;
        }
        if (!isZipCodeValid(defaultFormValues?.zipcode)) {
            setShowInvalidErroZipcode(true);
            return false;
        }
        if (!(defaultFormValues?.state)) {
            setShowErroState(true);
            return false;
        }
        if (!(defaultFormValues?.street && defaultFormValues?.street?.trim())) {
            setShowErroStreet(true);
            return false;
        }
        if (!(defaultFormValues?.neighborhood && defaultFormValues?.neighborhood?.trim())) {
            setShowErroNeighborhood(true);
            return false;
        }
        if (!(defaultFormValues?.city && defaultFormValues?.city?.trim())) {
            setShowErroCity(true);
            return false;
        }
                        
        return true;
    }
    // ================================================================================

    return (
        <>
                <>
                    <ModalCancel
                        id="modalcancelalert"
                        appear={isModalCancelVisible}
                        text={"Tem certeza que deseja cancelar a operação?"}
                        actionButton1={() => setModalCancelVisible(false)}
                        actionButton2={() => props.history.push(props.buttonCancelRoute)}
                    />

                    <ModalAddGestorRegional
                        id="modalconfirmalert"
                        appear={props.modalError}
                        text={props.messageError}
                        actionButton2={() => props.setModalError(false)}
                        history={props.history}
                        location={props.location}
                    />

                    <GlobalContainer>
                        <form onSubmit={handleSubmit(() => { if (isAllFieldsValidated()) props.onSubmitData(defaultFormValues); } ) }>
                            <ContentContainer>
                                <ContentContainer1>
                                    <TitleContainer>
                                        <Title> {props.pageTitle} </Title>
                                    </TitleContainer>
                                    <ContentContainer11>
                                        <div className="br-input">
                                            <label htmlFor="unitName">Nome da unidade<Mandatory>*</Mandatory></label>
                                            <input
                                                id="unitName-input"
                                                name="unitName"
                                                type="text"
                                                data-input="data-input"
                                                placeholder="Informe nome que facilite a identificação da unidade pelo cidadão"
                                                defaultValue={props.unit.unitName}
                                                onInput={e => { setDefaultFormValues({ ...defaultFormValues, unitName: e.target.value }); props.setUnit({ ...props.unit, unitName: e.target.value}); setShowErroUnitName(false); }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                style={{
                                                    "padding": "0px 7px 0px",
                                                    "fontSize": "14px",
                                                    "fontWeight": "normal",
                                                    "color": "#333333"
                                                }}
                                            />
                                            {showErroUnitName &&
                                                <span className="feedback danger" role="alert">
                                                    <i className="fas fa-times-circle" aria-hidden="true">
                                                        <img
                                                            src={FailIcon}
                                                            style={{ paddingBottom: '5px' }}
                                                            alt="failicon"
                                                        />
                                                    </i>
                                                    Este campo é obrigatório.
                                                </span>}
                                        </div>
                                    </ContentContainer11>
                                    
                                    <label>Endereço <span style={{"fontSize": "80%", "fontWeight": "normal"}}>(Informe o endereço completo para facilitar a identificação da unidade pelo cidadão)</span></label>
                                    <ContentContainer12>
                                        <ContentContainer13>
                                            <ContentContainer14>
                                                <div className="br-input">
                                                    <div>
                                                        <label htmlFor="zipcode">CEP<Mandatory>*</Mandatory></label>
                                                        <a style={{"paddingLeft": "10px", "fontSize": "70%", "fontWeight": "700"}} href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank" rel="noopener noreferrer">Não sei meu CEP</a>
                                                    </div>                                                    
                                                    <input
                                                        id="zipcode-input"
                                                        name="zipcode"
                                                        type="text"
                                                        data-input="data-input"
                                                        placeholder="Digite o CEP"
                                                        defaultValue={formatarCEP(props.unit.zipcode)}
                                                        value={formatarCEP(defaultFormValues.zipcode)}
                                                        onInput={e => { setDefaultFormValues({ ...defaultFormValues, zipcode: e.target.value }); setShowErroZipcode(false); setShowInvalidErroZipcode(false); if (isZipCodeValid(e.target.value)) { props.pesquisaCEP(e.target.value)} }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                        style={{
                                                            "padding": "0px 7px 0px",
                                                            "fontSize": "14px",
                                                            "fontWeight": "normal",
                                                            "color": "#333333"
                                                        }}
                                                    />
                                                    {showErroZipcode &&
                                                        <span className="feedback danger" role="alert">
                                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                                <img
                                                                    src={FailIcon}
                                                                    style={{ paddingBottom: '5px' }}
                                                                    alt="failicon"
                                                                />
                                                            </i>
                                                            Este campo é obrigatório.
                                                        </span>}
                                                    {showInvalidErroZipcode &&
                                                        <span className="feedback danger" role="alert">
                                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                                <img
                                                                    src={FailIcon}
                                                                    style={{ paddingBottom: '5px' }}
                                                                    alt="failicon"
                                                                />
                                                            </i>
                                                            CEP Inválido
                                                        </span>}
                                                </div>
                                            </ContentContainer14>
                                            <ContentContainer15>
                                                <ContentContainerBlock>
                                                    <div className="br-input" style={{"width": "80%"}}>
                                                        <label htmlFor="street">Logradouro (Rua/Avenida)<Mandatory>*</Mandatory></label>
                                                        <input
                                                            id="street-input"
                                                            name="street"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Rua/Avenida"
                                                            defaultValue={props.unit.street}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, street: e.target.value }); setShowErroStreet(false)}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                        {showErroStreet &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                Este campo é obrigatório.
                                                            </span>}
                                                    </div>
                                                    <div className="br-input" style={{"paddingLeft": "20px","width": "20%"}}>
                                                        <label htmlFor="number">Número</label>
                                                        <input
                                                            id="number-input"
                                                            name="number"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Número"
                                                            defaultValue={props.unit.number}
                                                            onInput={e => {setDefaultFormValues({ ...defaultFormValues, number: e.target.value });}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                    </div>
                                                </ContentContainerBlock>                                                
                                            </ContentContainer15>
                                            <ContentContainer15>
                                                <ContentContainerBlock>
                                                    <div className="br-input" style={{"width": "70%"}}>
                                                        <label htmlFor="addressComplement">Complemento</label>
                                                        <input
                                                            id="addressComplement-input"
                                                            name="addressComplement"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Complemento"
                                                            defaultValue={props.unit.addressComplement}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, addressComplement: e.target.value }); }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="br-input" style={{"paddingLeft": "20px","width": "40%"}}>
                                                        <label htmlFor="neighborhood">Bairro<Mandatory>*</Mandatory></label>
                                                        <input
                                                            id="neighborhood-input"
                                                            name="neighborhood"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Bairro"
                                                            defaultValue={props.unit.neighborhood}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, neighborhood: e.target.value }); setShowErroNeighborhood(false);}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                        {showErroNeighborhood &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                Este campo é obrigatório.
                                                            </span>}
                                                    </div>
                                                </ContentContainerBlock>                                                
                                            </ContentContainer15>
                                            <ContentContainer15>
                                                <ContentContainerBlock>
                                                    <div className="br-input" style={{"width": "70%", "marginRight": "20px"}}>
                                                        <label htmlFor="city">Cidade<Mandatory>*</Mandatory></label>
                                                        <input
                                                            id="city-input"
                                                            name="city"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Cidade"
                                                            defaultValue={props.unit.city}
                                                            onInput={e => { setDefaultFormValues({ ...defaultFormValues, city: e.target.value }); setShowErroCity(false)}} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                        {showErroCity &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                Este campo é obrigatório.
                                                            </span>}
                                                    </div>
                                                    <SelectContainer>
                                                        <label>Estado<Mandatory>*</Mandatory></label>
                                                        <Select
                                                            isDisabled={true}
                                                            onInput={(value) => {
                                                                setDefaultFormValues({ ...defaultFormValues, state: { value: value.value, label: value.label } }); //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                                if (value) {
                                                                    setShowErroState(false); //Limpa erro
                                                                }
                                                            }}
                                                            options={props.stateOptions}
                                                            value = {defaultFormValues.state}
                                                            placeholder="Selecione o estado"
                                                            styles={SelecStyles}
                                                            theme={theme => ({
                                                                ...theme,
                                                                borderRadius: 6,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#CCCCCC',
                                                                    primary: '#1351B4'
                                                                },
                                                            })}
                                                            components={
                                                                {
                                                                    DropdownIndicator,
                                                                    IndicatorSeparator
                                                                }
                                                            }
                                                        />
                                                    </SelectContainer>
                                                    
                                                    {showErroState &&
                                                        <span className="feedback danger" role="alert" id="cpfoperator">
                                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                                <img
                                                                    src={FailIcon}
                                                                    style={{ paddingBottom: '5px' }}
                                                                    alt="failicon"
                                                                />
                                                            </i>
                                                            Este campo é obrigatório.
                                                        </span>}
                                                </ContentContainerBlock>                                                
                                            </ContentContainer15>                     
                                        </ContentContainer13>
                                    </ContentContainer12>
                                    <label>Gestores regionais vinculados</label>
                                    <ContentContainer12>
                                        <ContentContainer13>
                                            <ContentContainer15>
                                                <ContentContainerBlock>
                                                    <div className="br-input" style={{"width": "30%"}}>
                                                        <div>
                                                            <label htmlFor="cpf">CPF</label>
                                                        </div>                                                    
                                                        <input
                                                            id="cpf-input"
                                                            name="cpf"
                                                            type="text"
                                                            data-input="data-input"
                                                            placeholder="Digite somente números"
                                                            maxLength="14"
                                                            defaultValue=""
                                                            value={cpfMask(cpf)}
                                                            onInput={e => { setCpf(e.target.value); setShowErroCpf(false); setShowInvalidErroCpf(false); }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                                            style={{
                                                                "padding": "0px 7px 0px",
                                                                "fontSize": "14px",
                                                                "fontWeight": "normal",
                                                                "color": "#333333"
                                                            }}
                                                        />
                                                        {showErroCpf &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                Este campo é obrigatório.
                                                            </span>}
                                                        {showInvalidErroCpf &&
                                                            <span className="feedback danger" role="alert">
                                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                                    <img
                                                                        src={FailIcon}
                                                                        style={{ paddingBottom: '5px' }}
                                                                        alt="failicon"
                                                                    />
                                                                </i>
                                                                CPF Inválido
                                                            </span>}
                                                    </div>
                                                    <div className="br-input" style={{"paddingLeft": "20px","width": "70%"}}>
                                                        <button className={"br-button primary "+(props.loadingIncludeGestor ? "loading" : "")} type="button"
                                                            onClick={() => {
                                                                if (props.loadingIncludeGestor) {
                                                                    return;
                                                                }
                                                                if (isCpfValid(cpf)) {                                                                     
                                                                    if (props.searchResult.filter(e => e.STATUS !== 'deleted' && e.CPF_USUARIO === removeInputMask(cpf)).length > 0) {  //Verifica se o gestor com estatus não deletado está na lista
                                                                        props.setModalError(true);
                                                                        props.setMessageError('Este gestor regional já se encontra incluído.');
                                                                    } else {
                                                                        let save = true;
                                                                        props.setSearchResult(props.searchResult.map(e => {  //Verificar se o gestor já se encontra na lista como "deletado". Em caso positivo, remover este status.
                                                                            if (e.STATUS === 'deleted' && e.CPF_USUARIO === removeInputMask(cpf)) {
                                                                                e.STATUS = 'undeleted'
                                                                                save = false;
                                                                            }
                                                                                return e;
                                                                            }
                                                                        ))
                                                                        if (save) { //Caso o gestor não esteja na lista, salvar no banco
                                                                            props.onSubmitCPF(cpf)
                                                                        }
                                                                    }                                           
                                                                    document.getElementById("cpf-input").value = "";
                                                                    setCpf("");
                                                                } else {
                                                                    setShowInvalidErroCpf(true);
                                                                }
                                                            }}>
                                                            Incluir
                                                        </button>
                                                    </div>
                                                </ContentContainerBlock>
                                            </ContentContainer15>
                                            <ContentContainer4>
                                                {!!(!(props.searchResult.length > 0)) && <>
                                                    <label style={{'color': 'red'}}>Não há gestores vinculados a esta unidade.</label>
                                                </>}
                                                {!!(props.searchResult && props.searchResult.length > 0) && <>
                                                    <label>{props.tableResultListTitle}</label>
                                                    <div className="br-table" data-search="data-search" data-selection="data-selection">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col0" style={{ 'color': '#1351B4' }}></th>
                                                                    <th scope="col1" style={{ 'color': '#1351B4' }}> Nome completo </th>
                                                                    <th scope="col2" style={{ 'color': '#1351B4' }}> CPF </th>
                                                                    <th scope="col4" style={{ 'color': '#1351B4' }}> Data de Cadastro </th>
                                                                    <th scope="col5" style={{ 'color': '#1351B4' }}> Data de Expiração </th>
                                                                    <th scope="col6" style={{ 'color': '#1351B4' }}> Ações </th>
                                                                    <th scope="col99" style={{ 'color': '#1351B4' }}></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {props.searchResult.filter(e => e.STATUS !== 'deleted').map(result => (
                                                                    <tr key={result.ID_USUARIO_ORGAO}>
                                                                        <td> </td>
                                                                        <td> {result.NOME_USUARIO} </td>
                                                                        <td> { cpfMask(result.CPF_USUARIO) }</td>
                                                                        <td> { formatDate(result.DATA_INCLUSAO) } </td>
                                                                        <td> {result.DATA_CANCELAMENTO === null ? "-" : formatDate(result.DATA_CANCELAMENTO) } </td>
                                                                        <td style={{width: "100px"}}>                                                
                                                                            <img
                                                                                src={TrashIcon}
                                                                                alt="trashIcon"
                                                                                title="Remover"
                                                                                style={{
                                                                                    'cursor': 'pointer'
                                                                                }}
                                                                                onClick={() => {
                                                                                    props.setSearchResult(
                                                                                        (result.STATUS === 'new' ) ?
                                                                                        props.searchResult.filter(e => e.ID_USUARIO_ORGAO !== result.ID_USUARIO_ORGAO)
                                                                                        :
                                                                                        props.searchResult.map(e => {
                                                                                            if (e.ID_USUARIO_ORGAO === result.ID_USUARIO_ORGAO){
                                                                                                e.STATUS = 'deleted';
                                                                                            }
                                                                                            return e
                                                                                        })
                                                                                    );                                                                                                                                                                        
                                                                                }}
                                                                            />
                                                                        </td> 
                                                                        <td> </td>
                                                                    </tr>
                                                                )
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <PaginationContainer>
                                                        <nav className="br-pagination" aria-label="Paginação de resultados" data-total="50" data-current="1" data-per-page="20">
                                                            <div className="pagination-per-page">
                                                                <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Exibir </label>
                                                                <SelectPagesContainer>
                                                                    <Select
                                                                        options={rangePagesOptions}
                                                                        defaultValue={{ value: "10", label: "10" }}
                                                                        value={props.rangePages}
                                                                        onChange={value => {
                                                                            if (props.searchResult.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                                props.setModalError(true);
                                                                                props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                            } else {
                                                                                props.setRangePages(value)
                                                                                props.onChangePaginationConfig(value, props.page, true)
                                                                            }
                                                                        }}
                                                                        styles={SelecPageStyles}
                                                                        theme={theme => ({
                                                                            ...theme,
                                                                            borderRadius: 6,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#CCCCCC',
                                                                                primary: '#1351B4'
                                                                            },
                                                                        })}
                                                                        components={
                                                                            {
                                                                                DropdownIndicator,
                                                                                IndicatorSeparator
                                                                            }
                                                                        }
                                                                    />
                                                                </SelectPagesContainer>
                                                            </div>

                                                            <span className="br-divider d-none d-sm-block mx-3"></span>

                                                            <div className="pagination-information d-none d-sm-flex">
                                                                <span className="current"> {1 + ((Number(props.page.value) - 1) * Number(props.rangePages.value))} </span>&ndash;<span className="per-page"> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className="total"> {props.totalFilter} </span>&nbsp;itens
                                                            </div>
                                                            <div className="pagination-go-to-page d-none d-sm-flex ml-auto">
                                                                <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Página </label>
                                                                <SelectPagesContainer>
                                                                    <Select
                                                                        options={props.pageOptions}
                                                                        defaultValue={{ value: "1", label: "1" }}
                                                                        value={props.page}
                                                                        onChange={value => {
                                                                            if (props.searchResult.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                                props.setModalError(true);
                                                                                props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                            } else {
                                                                                props.setPage(value)
                                                                                props.onChangePaginationConfig(props.rangePages, value, false)
                                                                            }
                                                                        }}
                                                                        styles={SelecPageStyles}
                                                                        theme={theme => ({
                                                                            ...theme,
                                                                            borderRadius: 6,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#CCCCCC',
                                                                                primary: '#1351B4'
                                                                            },
                                                                        })}
                                                                        components={
                                                                            {
                                                                                DropdownIndicator,
                                                                                IndicatorSeparator
                                                                            }
                                                                        }
                                                                    />
                                                                </SelectPagesContainer>
                                                            </div>

                                                            <span className="br-divider d-none d-sm-block mx-3"></span>

                                                            <div className="pagination-arrows ml-auto ml-sm-0">
                                                                <button className="br-button circle" type="button" aria-label="Voltar página"
                                                                    style={{
                                                                        'opacity': Number(props.page.value) > 1 ? '' : '0.4',
                                                                        'pointerEvents': Number(props.page.value) > 1 ? '' : 'none'
                                                                    }}
                                                                    onClick={() => {
                                                                        if (props.searchResult.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                            props.setModalError(true);
                                                                            props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                        } else {
                                                                            const new_page = {
                                                                                value: String(Number(props.page.value) - 1),
                                                                                label: String(Number(props.page.value) - 1)
                                                                            }
                                                                            props.setPage(new_page)
                                                                            props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-angle-left" aria-hidden="true">
                                                                        <img
                                                                            src={DropLeftIcon}
                                                                            alt="leftIcon"
                                                                            style={{ "marginBottom": "6px" }}
                                                                        />
                                                                    </i>
                                                                </button>
                                                                <button className="br-button circle" type="button" aria-label="Avançar página"
                                                                    style={{
                                                                        'opacity': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : '0.4',
                                                                        'pointerEvents': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : 'none'
                                                                    }}
                                                                    onClick={() => {
                                                                        if (props.searchResult.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                            props.setModalError(true);
                                                                            props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                        } else {
                                                                            const new_page = {
                                                                                value: String(Number(props.page.value) + 1),
                                                                                label: String(Number(props.page.value) + 1)
                                                                            }
                                                                            props.setPage(new_page)
                                                                            props.onChangePaginationConfig(props.rangePages, new_page, false)
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-angle-right" aria-hidden="true">
                                                                        <img
                                                                            src={DropRightIcon}
                                                                            alt="rightIcon"
                                                                            style={{ "marginBottom": "6px" }}
                                                                        />
                                                                    </i>
                                                                </button>
                                                            </div>
                                                        </nav>
                                                    </PaginationContainer>
                                                </>}
                                            </ContentContainer4>
                                        </ContentContainer13>
                                    </ContentContainer12>









                                    {!!(props.onChangePaginationConfigAttendant) &&
                                    <>
                                        <label>Atendentes vinculados</label>
                                        <ContentContainer13>
                                            <ContentContainer4>
                                                {!!(!(props.searchResultAttendant.length > 0)) && <>
                                                    <label style={{'color': 'red'}}>Não há atendentes vinculados a esta unidade.</label>
                                                </>}
                                                {!!(props.searchResultAttendant && props.searchResultAttendant.length > 0) && <>
                                                    <label>{props.tableResultListTitle}</label>
                                                    <div className="br-table" data-search="data-search" data-selection="data-selection">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col0" style={{ 'color': '#1351B4' }}></th>
                                                                    <th scope="col1" style={{ 'color': '#1351B4' }}> Nome completo </th>
                                                                    <th scope="col2" style={{ 'color': '#1351B4' }}> CPF </th>
                                                                    <th scope="col4" style={{ 'color': '#1351B4' }}> Data de Cadastro </th>
                                                                    <th scope="col5" style={{ 'color': '#1351B4' }}> Data de Expiração </th>
                                                                    <th scope="col99" style={{ 'color': '#1351B4' }}></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {props.searchResultAttendant.map(result => (
                                                                    <tr key={result.ID_USUARIO_ORGAO}>
                                                                        <td> </td>
                                                                        <td> {result.NOME_USUARIO} </td>
                                                                        <td> { cpfMask(result.CPF_USUARIO) }</td>
                                                                        <td> { formatDate(result.DATA_INCLUSAO) } </td>
                                                                        <td> {result.DATA_CANCELAMENTO === null ? "-" : formatDate(result.DATA_CANCELAMENTO) } </td>
                                                                        <td> </td>
                                                                    </tr>
                                                                )
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <PaginationContainer>
                                                        <nav className="br-pagination" aria-label="Paginação de resultados" data-total="50" data-current="1" data-per-page="20">
                                                            <div className="pagination-per-page">
                                                                <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Exibir </label>
                                                                <SelectPagesContainer>
                                                                    <Select
                                                                        options={rangePagesOptions}
                                                                        defaultValue={{ value: "10", label: "10" }}
                                                                        value={props.rangePagesAttendant}
                                                                        onChange={value => {
                                                                            if (props.searchResultAttendant.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                                props.setModalError(true);
                                                                                props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                            } else {
                                                                                props.setRangePagesAttendant(value)
                                                                                props.onChangePaginationConfigAttendant(value, props.pageAttendant, true)
                                                                            }
                                                                        }}
                                                                        styles={SelecPageStyles}
                                                                        theme={theme => ({
                                                                            ...theme,
                                                                            borderRadius: 6,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#CCCCCC',
                                                                                primary: '#1351B4'
                                                                            },
                                                                        })}
                                                                        components={
                                                                            {
                                                                                DropdownIndicator,
                                                                                IndicatorSeparator
                                                                            }
                                                                        }
                                                                    />
                                                                </SelectPagesContainer>
                                                            </div>

                                                            <span className="br-divider d-none d-sm-block mx-3"></span>

                                                            <div className="pagination-information d-none d-sm-flex">
                                                                <span className="current"> {1 + ((Number(props.pageAttendant.value) - 1) * Number(props.rangePagesAttendant.value))} </span>&ndash;<span className="per-page"> {Number(props.pageAttendant.value) * Number(props.rangePagesAttendant.value)} </span>&nbsp;de&nbsp;<span className="total"> {props.totalFilterAttendant} </span>&nbsp;itens
                                                            </div>
                                                            <div className="pagination-go-to-page d-none d-sm-flex ml-auto">
                                                                <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Página </label>
                                                                <SelectPagesContainer>
                                                                    <Select
                                                                        options={props.pageOptionsAttendant}
                                                                        defaultValue={{ value: "1", label: "1" }}
                                                                        value={props.pageAttendant}
                                                                        onChange={value => {
                                                                            if (props.searchResultAttendant.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                                props.setModalError(true);
                                                                                props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                            } else {
                                                                                props.setPageAttendant(value)
                                                                                props.onChangePaginationConfigAttendant(props.rangePagesAttendant, value, false)
                                                                            }
                                                                        }}
                                                                        styles={SelecPageStyles}
                                                                        theme={theme => ({
                                                                            ...theme,
                                                                            borderRadius: 6,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#CCCCCC',
                                                                                primary: '#1351B4'
                                                                            },
                                                                        })}
                                                                        components={
                                                                            {
                                                                                DropdownIndicator,
                                                                                IndicatorSeparator
                                                                            }
                                                                        }
                                                                    />
                                                                </SelectPagesContainer>
                                                            </div>

                                                            <span className="br-divider d-none d-sm-block mx-3"></span>

                                                            <div className="pagination-arrows ml-auto ml-sm-0">
                                                                <button className="br-button circle" type="button" aria-label="Voltar página"
                                                                    style={{
                                                                        'opacity': Number(props.pageAttendant.value) > 1 ? '' : '0.4',
                                                                        'pointerEvents': Number(props.pageAttendant.value) > 1 ? '' : 'none'
                                                                    }}
                                                                    onClick={() => {
                                                                        if (props.searchResultAttendant.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                            props.setModalError(true);
                                                                            props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                        } else {
                                                                            const new_page = {
                                                                                value: String(Number(props.pageAttendant.value) - 1),
                                                                                label: String(Number(props.pageAttendant.value) - 1)
                                                                            }
                                                                            props.setPageAttendant(new_page)
                                                                            props.onChangePaginationConfigAttendant(props.rangePagesAttendant, new_page, false)
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-angle-left" aria-hidden="true">
                                                                        <img
                                                                            src={DropLeftIcon}
                                                                            alt="leftIcon"
                                                                            style={{ "marginBottom": "6px" }}
                                                                        />
                                                                    </i>
                                                                </button>
                                                                <button className="br-button circle" type="button" aria-label="Avançar página"
                                                                    style={{
                                                                        'opacity': Number(props.pageAttendant.value) < (Math.ceil(Number(props.totalFilterAttendant) / Number(props.rangePagesAttendant.value))) ? '' : '0.4',
                                                                        'pointerEvents': Number(props.pageAttendant.value) < (Math.ceil(Number(props.totalFilterAttendant) / Number(props.rangePagesAttendant.value))) ? '' : 'none'
                                                                    }}
                                                                    onClick={() => {
                                                                        if (props.searchResultAttendant.filter(e => e.STATUS === 'deleted' || e.STATUS === 'new').length > 0) {
                                                                            props.setModalError(true);
                                                                            props.setMessageError('Existem mudanças não salvas. Por favor, salve as mudanças antes de continuar.');
                                                                        } else {
                                                                            const new_page = {
                                                                                value: String(Number(props.pageAttendant.value) + 1),
                                                                                label: String(Number(props.pageAttendant.value) + 1)
                                                                            }
                                                                            props.setPageAttendant(new_page)
                                                                            props.onChangePaginationConfigAttendant(props.rangePagesAttendant, new_page, false)
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fas fa-angle-right" aria-hidden="true">
                                                                        <img
                                                                            src={DropRightIcon}
                                                                            alt="rightIcon"
                                                                            style={{ "marginBottom": "6px" }}
                                                                        />
                                                                    </i>
                                                                </button>
                                                            </div>
                                                        </nav>
                                                    </PaginationContainer>
                                                </>}
                                            </ContentContainer4>
                                        </ContentContainer13>
                                        </>
                                    
                                    }

                                    <span style={{"fontSize": "80%"}}>São obrigatórios os campos marcados com<Mandatory>*</Mandatory></span>
                                </ContentContainer1>
                            </ContentContainer>

                            <ContentContainerButtons>
                                <button className="br-button" type="button"
                                    onClick={() => setModalCancelVisible(true)}>
                                    Voltar
                                </button>

                                {!props.loadingAdd ?
                                    <button className="br-button primary" type="submit">
                                        Confirmar
                                    </button>
                                    :
                                    <button className="br-button primary loading" type="button">
                                        Confirmar
                                    </button>
                                }
                            </ContentContainerButtons>

                        </form>
                    </GlobalContainer>
                </>            
        </>
    );
}