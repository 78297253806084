import styled from 'styled-components';

export const Text = styled.label `
    font-size: 14px;
    font-style: italic;
`;

export const Text2 = styled.label `
    font-size: 12px;
    font-style: italic;
    color: #555555;
`;

export const Text3 = styled.label `
    font-size: 12px;
    font-style: italic;
    color: white;
    background-color: #1351b4;
    cursor: pointer;
    border-radius: 100em;
    padding: 5px 10px;
    text-align: center;
`;

export const HeaderUp = styled.div `
    position: relative;
    top: 0px;
    width: 100%;

    #UserInfo {
        display: ${ props => props.showInfo ? '' : 'none'};
    }
    
    @media print {
        div {
            display: none;
        }        
    }
`;