import React from 'react'

import { ModalCenterFont } from './styles'
import { Link } from 'react-router-dom'
//import '../../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';;

function ModalWarn({ appear, text, actionButton2, textButton2, logout, title }) {
  return (
    <ModalCenterFont appear={appear}>
      <div className='br-modal is-medium' id='modal'>
        <div className='br-card'>
          <div className='container-fluid p-4'>
            {
              title && <div className="br-modal-header">
                          <div className="br-modal-title">
                              <strong>{title}</strong>
                          </div>
                      </div>
            }
            {/* <div className='br-modal-body' >{text}</div> */}
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <div className='br-modal-footer justify-content-center'>
              {logout ? (
                <Link className='br-button primary small m-2' to='/logout'>
                  {textButton2}
                </Link>
              ) : (
                <button className='br-button primary small m-2' type='button' onClick={actionButton2}>
                  {textButton2}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalCenterFont>
  )
}

export default ModalWarn
