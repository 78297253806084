import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import { GlobalContainer,
         ContentContainer,
         ContentContainer1,
         ContentContainer11,
         ContentContainer2,
         Title,
         TitleContainer,
         ContentContainerButtons} from './styles';
import ModalWarn from '../Modal/Warn/index';

import FailIcon from '../../assets/fail_icon.png';
import { cpfMask, isCpfValid } from '../../utils';

export default function Search(props) {
    const {handleSubmit } = useForm();
    const [cpf, setCpf] = useState("");
    const [showCpfRequired, setShowCpfRequired] = useState(false);
    const [showInvalidErroCpf, setShowInvalidErroCpf] = useState(false);
    return (
        <>
            <ModalWarn
                id="modalconfirmalert"
                appear={props.modalError}
                text = {props.messageError}
                textButton2='Ok'
                actionButton2={() => props.setModalError(false)}
            />

            <GlobalContainer>
                <TitleContainer>
                    <Title> {props.pageTitle} </Title>
                </TitleContainer>
                
                <form onSubmit={handleSubmit(() => {
                        if (!(cpf && cpf.trim().length > 0)) {
                            setShowCpfRequired(true);
                        } else if (isCpfValid(cpf)) {
                            props.onSubmitCPF(cpf);
                        } else {
                            setShowInvalidErroCpf(true);
                        } 
                    })}>
                    <ContentContainer>
                        <ContentContainer1>
                                <ContentContainer11>
                                    <div className="br-input has-icon">
                                        <label htmlFor="cpf"> {props.cpfLabel ? props.cpfLabel : 'CPF'} </label>
                                        <input className="has-icon"
                                            id="cpf" 
                                            name="cpf"
                                            type="text" 
                                            placeholder="Digite somente números"
                                            maxLength="14"
                                            defaultValue=""
                                            value={cpfMask(cpf)}
                                            onInput={e => { 
                                                setCpf(e.target.value);
                                                setShowCpfRequired(false);
                                                setShowInvalidErroCpf(false); 
                                            }} //Foi usado o onInput ao invés do onChange para corrigir um bug que ocorre quando se seleciona o dado e o apaga usando o botão "Del"
                                        />
                                    </div>
                                    {showCpfRequired &&
                                            <span className="feedback danger" role="alert">
                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                    <img
                                                        src={FailIcon}
                                                        style={{ paddingBottom: '5px' }}
                                                        alt="failicon"
                                                    />
                                                </i>
                                                CPF Obrigatório
                                            </span>
                                    }
                                    {showInvalidErroCpf &&
                                        <>
                                            <span className="feedback danger" role="alert">
                                                <i className="fas fa-times-circle" aria-hidden="true">
                                                    <img
                                                        src={FailIcon}
                                                        style={{ paddingBottom: '5px' }}
                                                        alt="failicon"
                                                    />
                                                </i>
                                                CPF Inválido
                                            </span>
                                            {props.setCpfSearchErro(false)}
                                        </>
                                    }
                                    {props.cpfSearchErro &&
                                        <span className="feedback danger" role="alert"
                                            id = "cpfsearch">
                                            <i className="fas fa-times-circle" aria-hidden="true">
                                                <img
                                                    src={FailIcon}
                                                    style = {{ paddingBottom: '5px' }}
                                                    alt="failicon"
                                                />
                                            </i>
                                            <label id="cpfsearchlabel">{props.cpfSearchErroMessage}</label>
                                        </span>
                                    }
                                </ContentContainer11>
                        </ContentContainer1>

                        <ContentContainer2>
                            
                        </ContentContainer2>
                    </ContentContainer>

                    <ContentContainer>
                        <ContentContainer1>

                        </ContentContainer1>

                        <ContentContainer2>
                            <ContentContainerButtons>
                                <button className="br-button" type="button"
                                        onClick={() => props.history.push('/pesquisarcidadao')}>
                                    Voltar
                                </button>

                                {!props.buttonNextLoading ?
                                    <button className="br-button primary" type="submit">
                                        Avançar
                                    </button> 
                                :
                                    <button className="br-button primary loading" type="button">
                                        Avançar
                                    </button>
                                }
                            </ContentContainerButtons>
                        </ContentContainer2>
                    </ContentContainer>
                </form>
            </GlobalContainer>
        </>
    );
}