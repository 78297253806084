import React, { useState, useEffect } from 'react'
//import InputMask from 'react-input-mask';

import { useForm, Controller } from 'react-hook-form'
import { uniqueId } from 'lodash'
import filesize from 'filesize'

// importando o estilo (tag) componentizada
import {
  GlobalContainer,
  TitleContainer,
  Title,
  SubTitleContainer,
  SubTitle,
  Text,
  DescContainer,
  TextDesc,
  ContentContainer,
  ContentContainer1,
  ContentContainer11,
  ContentContainer2,
  ContentContainer21,
  FileContent,
  ContentContainerButtons,
  Line,
  Col1,
  Col2,
  Col3,
  Col4,
  Text2,
  FileInputDiv,
} from './styles'
import Select, { components } from 'react-select'

//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.css'; // percebi que nao e necessario, mas nao sei o pq
//import '../../../node_modules/@govbr/dsgov/dist/dsgov.min.js';  //tentativa de importar .js (FAIL)

import DropDownIcon from '../../assets/drop_down_icon.png'
import DropUpIcon from '../../assets/drop_up_icon.png'
import TrashIcon from '../../assets/trash_icon.png'
import UploadIcon from '../../assets/upload_icon.png'
import FailIcon from '../../assets/fail_icon.png'
import ModalCancel from '../../Components/Modal/Cancel/index'
import ModalWarn from '../../Components/Modal/Warn/index'
import ModalError from '../../Components/Modal/Warn/index'

const cpfMask = (cpf) => {
  var num = cpf?.replace(/[^\d]/g, '') //remove todos os caracteres não numéricos
  var len = num.length //guarda o número de digitos até o momento

  if (len <= 6) {
    cpf = num.replace(/(\d{3})(\d{1,3})/g, '$1.$2')
  } else if (len <= 9) {
    cpf = num.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3')
  } else {
    cpf = num.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4')
  }
  return cpf
}

export default function AttorneyData(props) {
  const [loadAttorney, setLoadAttorney] = useState(false)

  const [loading, setLoading] = useState(false)

  const [attorney, setAttorney] = useState({
    id_attendance: '',
    attendent: '',
    cpf_attendant: '',
    person: '',
    name: '',
    socialName: '',
    cpf: '',
  })

  const [typeOptions, setTypeOption] = useState([
    /*
        { value: "1", label: "Procuração" },
        { value: "2", label: "Documento de Identificação" },
        { value: "3", label: "Carteira OAB" },
        { value: "99", label: "Outros" },
        */
  ])

  const [documentType, setDocumentType] = useState({ value: '', label: 'Selecione o tipo do documento' })
  const [documentsList, setDocumentsList] = useState([])
  const [documentsListBase64, setDocumentsListBase64] = useState([])
  const [lastIdDocumentList, setLastIdDocumentList] = useState('0')

  const [citizen, setCitizen] = useState({
    cpf_citizen: '',
  })

  useEffect(() => {
    if (!loadAttorney && props.location.state !== undefined) {
      setAttorney({
        id_attendance: props.location.state.id_attendance,
        attendent: props.location.state.attendent,
        cpf_attendant: props.location.state.cpf_attendant,
        person: props.location.state.person,
        name: props.location.state.name_attorney,
        socialName: props.location.state.social_name_attorney,
        cpf: props.location.state.cpf_attorney,
      })

      setLoadAttorney(true)

      if (props.location.state.document_list !== '') {
        setDocumentsList(props.location.state.document_list)
        setDocumentsListBase64(props.location.state.documents)
        setDocumentType(props.location.state.document_type)
        setLastIdDocumentList(props.location.state.last_id_list)
      }
      if (props.location.state.cpf_citizen !== '') {
        setCitizen({
          cpf_citizen: props.location.state.cpf_citizen,
        })
      }

      async function loadTypeOptions() {
        const requestOptions = {
          method: 'GET',
          headers: new Headers({
            authorization: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
          }),
        }

        await fetch(`${process.env.REACT_APP_API_URL}/pesquisaDocumento`, requestOptions)
          .then(function (response) {
            if (response.ok) {
              response.json().then(function (data) {
                var typeList = []
                var obj = {}

                data.forEach((type) => {
                  obj = {
                    value: type.TIPO_DOC,
                    label: type.DESCRICAO_DOC,
                  }

                  typeList.push(obj)
                })

                //console.log('/pesquisaDocumento -> ', data);
                setTypeOption(typeList)
              })
            } else {
              console.log('Error request to /pesquisaDocumento')
            }
          })
          .catch(function (err) {
            console.error('Failed retrieving information', err)
          })
      }

      loadTypeOptions()
    }
  }, [props.location.state, props, loadAttorney])

  const [isModalCancelVisible, setModalCancelVisible] = useState(false)

  const [modalError, setModalError] = useState(false)
  const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
  const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
  const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
  // Para exibir mensagem de Atendente <> Cidadao
  const [modalErrorAtend, setModalErrorAtend] = useState(false)

  // ESTILIZACAO PARA O SELECT ======================================================
  const SelecStyles = {
    control: (defaultStyles, state) => ({
      ...defaultStyles,
      borderWidth: state.isFocused ? 2.5 : 1,
      borderColor: state.isFocused ? '#E60000' : '#888',
      '&:hover': {
        backgroundColor: '#C5D4EB',
      },
      marginTop: 6,
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: 13.5,
      fontWeight: 355,
      marginLeft: 7,
      fontStyle: 'italic',
    }),
  }

  const DropdownIndicator = (props) => {
    return <components.DropdownIndicator {...props}>{props.selectProps.menuIsOpen ? <img src={DropUpIcon} alt='upicon' /> : <img src={DropDownIcon} alt='downicon' />}</components.DropdownIndicator>
  }

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={{}} {...innerProps} />
  }
  // ================================================================================

  const actionButtonModalCancelCancel = () => {
    setModalCancelVisible(false)
  }

  const addDocumentBase64 = (doc, file) => {
    var reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      var base64 = reader.result

      setDocumentsListBase64(
        documentsListBase64.concat({
          file: base64,
          id: doc.id,
          type: documentType.value,
          name: doc.name,
          size: doc.size,
        })
      )
    }

    reader.onerror = (error) => {
      console.log('Error: ', error)
    }
  }

  const addDocumentList = (doc) => {
    const docAdd = Array.from(doc.target.files)

    const files = docAdd.map((file) => ({
      file, // proprio arquivo
      id: (Number(uniqueId()) + Number(lastIdDocumentList)).toString(),
      type: documentType.label, // tipo do arquivo (SELECT)
      name: file.name,
      size: filesize(file.size),
    }))

    setDocumentsList(documentsList.concat(files))

    addDocumentBase64(files[0], doc.target.files[0])

    setLastIdDocumentList(files[0].id)
  }

  const deleteDocumentList = (doc) => {
    const docDel = doc.target.id

    setDocumentsList(documentsList.filter((docList) => docList.id !== docDel))

    setDocumentsListBase64(documentsListBase64.filter((docList) => docList.id !== docDel))
  }

  const { register, handleSubmit, errors, control } = useForm()
  const { name, socialName, cpf } = attorney

  const removeInputMask = (cpf) => {
    if (cpf === '') {
      return ''
    } else {
      return cpf.replace(/[^\d]+/g, '')
    }
  }

  const onSubmit = async (data) => {
    //console.log(data);
    //console.log('Document list -> ', documentsList);
    //console.log('Document list Base64 -> ', documentsListBase64);
    //console.log('Last id -> ', lastIdDocumentList);

    document.getElementById('cpfcitizen').style.display = 'none'

    data.cpf = removeInputMask(data.cpf)

    setLoading(true)

    const user = JSON.parse(localStorage.getItem('user'))

    // Pesquisar se é conta especial
    const reqOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: user ? user.token : '',
      }),
      body: JSON.stringify({
        cpf_cidadao: data.cpf,
      }),
    }

    let errorSpecialAccount = false;
    await fetch(`${process.env.REACT_APP_API_URL}/atendimento/verificaPEP`, reqOptions).then(
      function (response) {
        if (!response.ok) {
          if (response.status === 422) {
            response.json().then(function (err) {
                document.getElementById('cpfcitizen').style.display = 'block'
                document.getElementById('cpfcitizenlabel').innerText = (err.message ? err.message : 'CPF inválido. Verifique o documento original.')
            });
          } else if (response.status === 403) {
            response.json().then(function (data) {
              setModalInvalidSessionErrorTitle(data.title ? data.title : null)
              setModalInvalidSessionErrorMsg(data.message ? data.message : null)
              setLoading(false)
              setModalInvalidSessionError(true)
            })
          } else {
            document.getElementById('cpfcitizen').style.display = 'block'
            document.getElementById('cpfcitizenlabel').innerText = 'CPF inválido. Verifique o documento original.'
          }
          setLoading(false)
          errorSpecialAccount = true;
        }
      }
    );

    if (errorSpecialAccount)
      return;
    // Fim Pesquisar Conta especial

    // CONSULTA CIDADAO
    var citizenData = {
      id_attendance: '',
      attendent: '',
      cpf_attendant: '',
      person: '',
      ident_dig: '',
      cpf: '',
      name: '',
      birth_date: '',
      mothers_name: '',
      email: '',
      cell: '',
      documents: '',
      name_attorney: '', // DAQUI PARA BAIXO SAO INFORMACOES NECESSARIAS PARA O BOTAO VOLTAR NA PROXIMA TELA
      cpf_attorney: '',
      document_list: '',
      document_type: '',
      last_id_list: '',
    }

    // CPF informado do cidadão é igual ao do atendente
    if (user.cpf_atendente === data.cpf) {
      setLoading(false)
      setModalErrorAtend(true)
      return false
    }

    // alert('cpf_atendente: ' + user.cpf_atendente)
    // alert('cpf_cidadao: ' + data.cpf)
    // alert('cpf_procurador: ' + attorney.cpf)
    const requestOptions = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        cpfAtendente: user.cpf_atendente,
        cpfCidadao: data.cpf,
        cpfProcurador: attorney.cpf,
        authorization: user.token,
        codigo_unidade: user.codigo_unidade
      }),
    }
    await fetch(`${process.env.REACT_APP_API_URL}/atendimento/cidadao`, requestOptions)
      .then(function (response) {
        if (response.ok) {
          response.json().then(function (data) {
            // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

            if (data.status === 'BL') {
              document.getElementById('cpfcitizen').style.display = 'block'
              document.getElementById('cpfcitizenlabel').innerText = 'CPF com conta bloqueada por decisão administrativa ou judicial.'
              setLoading(false)
              return
            }

            if (data.nome_cidadao) {
              citizenData = {
                id_attendance: attorney.id_attendance,
                attendent: attorney.attendent,
                cpf_attendant: attorney.cpf_attendant,
                person: 'procurador',
                ident_dig: data.id_digital === 'true' ? true : false,
                cpf: data.cpf_cidadao,
                selo_conta: data.selo_conta,
                name: data.nome_cidadao,
                social_name: data.nome_social,
                birth_date: data.data_nascimento,
                mothers_name: data.nome_mae,
                email: data.email_cidadao,
                cell: data.celular_cidadao,
                documents: documentsListBase64,
                name_attorney: attorney.name, // DAQUI PARA BAIXO SAO INFORMACOES NECESSARIAS PARA O BOTAO VOLTAR NA PROXIMA TELA
                cpf_attorney: attorney.cpf,
                document_list: documentsList,
                document_type: documentType,
                last_id_list: lastIdDocumentList,
              }

              setLoading(false)
              props.history.push('/dadoscidadao', citizenData)
            } else {
              setLoading(false)
              setModalError(true)
              console.log('Response error in atendimento/cidadao BackendBalcao')
            }
          })
        } else if (response.status === 422) {
          response.json().then(function (data) {
            if (!!data.errorCode && !!data.message) {
              // inserir a mensagem: data.message no aviso de erro
              document.getElementById('cpfcitizen').style.display = 'block'
              document.getElementById('cpfcitizenlabel').innerText = data.message
            } else {
              // inserir mensagem genérica
              setLoading(false)
              setModalError(true)
              console.log('Response error in atendimento/cidadao BackendBalcao')
            }
            setLoading(false)
          })
        } else if (response.status === 403) {
          response.json().then(function (data) {
            setModalInvalidSessionErrorTitle(data.title ? data.title : null)
            setModalInvalidSessionErrorMsg(data.message ? data.message : null)
            setLoading(false)
            setModalInvalidSessionError(true)
          })
        } else {
          // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
          setLoading(false)
          setModalError(true)
          console.log('Request error in atendimento/cidadao BackendBalcao')
        }
      })
      .catch(function (err) {
        setLoading(false)
        setModalErrorAtend(true)
        console.error('Failed retrieving information', err)
      })
  }

  return (
    <>
      {!loadAttorney ? (
        <div style={{ marginTop: '75px' }}>
          <h1> Error on the page! </h1>
        </div>
      ) : (
        <>
          <ModalCancel
            id='modalcancelalert'
            appear={isModalCancelVisible}
            text={'Tem certeza que deseja cancelar o atendimento?'}
            actionButton1={actionButtonModalCancelCancel}
            actionButton2={() => props.history.push('/pesquisarcidadao')}
          />

          <ModalWarn
            id='modalconfirmalert'
            appear={modalError}
            text={ 'Houve algum problema no servidor. Aguarde um momento e tente novamente.'}
            textButton2='Ok'
            actionButton2={() => setModalError(false)}
          />

        <ModalWarn
          id='modalWarnalertInvalidSession'
          appear={modalInvalidSessionError}
          title = { modalInvalidSessionErrorTitle }
          text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Houve algum problema no servidor. Aguarde um momento e tente novamente.'}
          textButton2='Ir para tela inicial'          
          actionButton2={() => { 
            document.getElementById("btnLogout").click();
          }}
        />

          <ModalError id='modalconfirmalert' appear={modalErrorAtend} text='CPF informado do cidadão é igual ao do atendente.' textButton2='Ok' actionButton2={() => setModalErrorAtend(false)} />

          <GlobalContainer>
            <TitleContainer>
              <Title> Criar Conta gov.br </Title>
            </TitleContainer>

            <SubTitleContainer>
              <SubTitle> Dados do Procurador </SubTitle>
            </SubTitleContainer>

            <ContentContainer>
              <ContentContainer1>
                <ContentContainer11>
                  <label> CPF </label>
                  <Text>{cpfMask(cpf)}</Text>
                </ContentContainer11>
              </ContentContainer1>

              <ContentContainer2>
                <ContentContainer21>
                  <label> Nome </label>
                  <Text>{socialName ? socialName : name }</Text>
                </ContentContainer21>
              </ContentContainer2>
            </ContentContainer>

            <SubTitleContainer>
              <SubTitle> Documentos de comprovação de representação do cidadão </SubTitle>
            </SubTitleContainer>

            <DescContainer>
              <TextDesc>Os documentos devem estar no formato PDF e o tamanho de cada arquivo não deve ultrapassar 5MB.</TextDesc>
            </DescContainer>

            <form onSubmit={handleSubmit(onSubmit)}>
              <ContentContainer>
                <ContentContainer1>
                  <ContentContainer11>
                    <label> Tipo do Documento </label>
                    <Controller
                      name='document_type'
                      onChange={(e) => setDocumentType(e.value)}
                      control={control}
                      options={typeOptions}
                      defaultValue={documentType.value === '' ? '' : documentType}
                      render={(props) => (
                        <Select
                          onChange={(value) => {
                            props.onChange(value)
                            setDocumentType({ value: value.value, label: value.label })
                          }}
                          options={typeOptions}
                          defaultValue={documentType.value === '' ? '' : documentType}
                          value={documentType.value === '' ? '' : documentType}
                          styles={SelecStyles}
                          placeholder='Selecione o tipo do documento'
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            colors: {
                              ...theme.colors,
                              primary25: '#CCCCCC',
                              primary: '#1351B4',
                            },
                          })}
                          components={{ DropdownIndicator, IndicatorSeparator }}
                        />
                      )}
                      rules={{
                        required: true,
                        validate: (value) => value !== '',
                      }}
                    />
                    {errors.document_type && (
                      <span className='feedback danger' role='alert'>
                        <i className='fas fa-times-circle' aria-hidden='true'>
                          <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='fail' />
                        </i>
                        <label>Selecione o tipo do documento.</label>
                      </span>
                    )}
                  </ContentContainer11>
                </ContentContainer1>

                <ContentContainer2>
                  <ContentContainer21>
                    {documentType.value !== '' && documentType.label !== '' ? (
                      <FileInputDiv selectedType={true}>
                        <label className='title_input_file'> Documento </label>
                        <label className='content_input_file' htmlFor='file_input'>
                          <i className='fas fa-heartbeat' aria-hidden='true'>
                            <img src={UploadIcon} alt='uploadicon' />
                          </i>
                          Selecione o Documento
                        </label>
                        <input
                          id='file_input'
                          name='file_input'
                          type='file'
                          accept='application/pdf'
                          onChange={addDocumentList}
                          ref={register({
                            required: false,
                            validate: {
                              hasDocList: () => (!!documentsList.length ? true : 'Selecione o documento.'),
                              sizeLimit: () => {
                                for (let j = 0; j < documentsList.length; j++) {
                                  if (documentsList[j].file.size / 1000000 > 5) {
                                    return 'Cada documento deve conter no máximo 5 MB.'
                                  } else if (documentsList[j].file.size / 1000000 === 0) {
                                    return 'Documento vazio inserido.'
                                  }
                                }
                                return true
                              },
                              documentTypeRepeat: () => {
                                let cont = 0
                                for (let i = 0; i < typeOptions.length; i++) {
                                  cont = 0
                                  for (let j = 0; j < documentsList.length; j++) {
                                    if (documentsList[j].type === typeOptions[i].label) {
                                      cont += 1
                                      if (cont > 1) {
                                        return 'Insira apenas um documento por tipo.'
                                      }
                                    }
                                  }
                                }
                                return true
                              },
                            },
                          })}
                        />
                        {errors?.file_input && (
                          <span className='feedback danger' role='alert'>
                            <i className='fas fa-times-circle' aria-hidden='true'>
                              <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                            </i>
                            <label>{errors.file_input.message}</label>
                          </span>
                        )}
                      </FileInputDiv>
                    ) : (
                      <FileInputDiv selectedType={false}>
                        <label className='title_input_file'> Documento </label>
                        <label className='content_input_file' htmlFor='file_input'>
                          <i className='fas fa-heartbeat' aria-hidden='true'>
                            <img src={UploadIcon} alt='uploadicon' />
                          </i>
                          Selecione o Documento
                        </label>
                      </FileInputDiv>
                    )}
                  </ContentContainer21>
                </ContentContainer2>
              </ContentContainer>

              <ContentContainer>
                <FileContent>
                  {!!documentsList.length &&
                    documentsList.map((doc) => (
                      <li key={doc.id} style={{ listStyleType: 'none' }}>
                        <div className='br-list'>
                          <Line>
                            <Col1>
                              <div className='br-item'>{doc.type}</div>
                            </Col1>
                            <Col2>
                              <div className='br-item'>
                                <Text2>{doc.name}</Text2>
                              </div>
                            </Col2>
                            <Col3>
                              <div className='br-item'>{doc.size}</div>
                            </Col3>
                            <Col4>
                              <div className='br-item'>
                                <i className='fas fa-heartbeat' aria-hidden='true'>
                                  <img id={doc.id} className='trash_icon' style={{ paddingBottom: '12px' }} src={TrashIcon} onClick={deleteDocumentList} alt='trashicon' />
                                </i>
                              </div>
                            </Col4>
                          </Line>
                          <span className='br-divider'></span>
                        </div>
                      </li>
                    ))}
                </FileContent>
              </ContentContainer>

              <SubTitleContainer>
                <SubTitle> Cidadão </SubTitle>
              </SubTitleContainer>

              <ContentContainer>
                <ContentContainer1>
                  <ContentContainer11>
                    <div className='br-input'>
                      <label htmlFor='cpf'> CPF </label>
                      <input
                        id='cpf'
                        name='cpf'
                        placeholder='Digite somente números'
                        type='text'
                        maxLength='14'
                        defaultValue={cpfMask(citizen.cpf_citizen)}
                        ref={register({
                          required: { value: true, message: 'Digite o CPF.' },
                          minLength: { value: 14, message: 'CPF inválido.' },
                          maxLength: { value: 14, message: 'CPF inválido.' },
                        })}
                        onChange={(event) => {
                          const { value } = event.target
                          event.target.value = cpfMask(value)
                        }}
                      />
                    </div>
                    {errors?.cpf && (
                      <span className='feedback danger' role='alert'>
                        <i className='fas fa-times-circle' aria-hidden='true'>
                          <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                        </i>
                        {<script>{(document.getElementById('cpfcitizen').style.display = 'none')}</script>}
                        <label>{errors.cpf.message}</label>
                      </span>
                    )}
                    <span className='feedback danger' role='alert' id='cpfcitizen' style={{ display: 'none' }}>
                      <i className='fas fa-times-circle' aria-hidden='true'>
                        <img src={FailIcon} style={{ paddingBottom: '5px' }} alt='failicon' />
                      </i>
                      <label id='cpfcitizenlabel'></label>
                    </span>
                  </ContentContainer11>
                </ContentContainer1>

                <ContentContainer2></ContentContainer2>
              </ContentContainer>

              <ContentContainer>
                <ContentContainer1></ContentContainer1>

                <ContentContainer2>
                  <ContentContainerButtons>
                    <button className='br-button' type='button' onClick={setModalCancelVisible}>
                      Cancelar
                    </button>

                    {!loading ? (
                      <button className='br-button primary' type='submit'>
                        Avançar
                      </button>
                    ) : (
                      <button className='br-button loading' type='button'>
                        Avançar
                      </button>
                    )}
                  </ContentContainerButtons>
                </ContentContainer2>
              </ContentContainer>
            </form>
          </GlobalContainer>
        </>
      )}
    </>
  )
}
 