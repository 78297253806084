import React, { useState, useEffect } from 'react';

import UnitElement from '../../Components/UnitElement'
import { estados } from '../../constants'
import ModalWarn from '../../Components/Modal/Warn/index'
import { removeInputMask } from '../../utils'

export default function AddUnit(props) {

    const [ unit, setUnit ] = useState({
        unitName: '',
        zipcode: "",
        state: '',
        street: '',
        number: '',
        addressComplement: '',
        neighborhood: '',
        city: ''
    });

    const [ stateOptions ] = useState(estados);

    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)
    const [userList, setUserList ] = useState([]);
    const [rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [pageOptions, setPageOptions ] = useState([]);
    const [page, setPage ] = useState({
        label: "1", value: "1"
    });
    const [totalFilter, setTotalFilter ] = useState(0);

    useEffect(()=>{
        setUnit( {...unit, organCode: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).codigo_orgao : ""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ loadingAdd, setLoadingAdd ] = useState(false);    
    const [ modalError, setModalError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");
    const [ loadingIncludeGestor, setLoadingIncludeGestor ] = useState(false);

     // ================================================================================

    const onSubmitUnitData = async (data) => {

        setLoadingAdd(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                codigo_orgao: data.organCode,
                nome_unidade: data.unitName,
                cep: data.zipcode,
                estado: data.state.value,
                logradouro: data.street,
                numero: data.number,
                complemento: data.addressComplement,
                bairro: data.neighborhood,
                cidade: data.city,
                gestores_regionais: userList
            }),
        };
         
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/unidade/cadastraUnidadeOrgao`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data_request) {
                    const unit_added = {
                        message: data_request.message
                    }
                    setLoadingAdd(false);
                    
                    props.history.push('gerenciarUnidades', unit_added);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    data.message = data.messages ? data.messages[0].message : data.message
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/unidade/cadastraUnidadeOrgao BackendBalcao');
                    }

                    setLoadingAdd(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingAdd(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingAdd(false);
                console.log('Error request to /gestao/unidade/cadastraUnidadeOrgao');
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    };

    const pesquisaCEP = async (cep) => {
        cep = cep.replace(/\D/g, '')

        setLoadingAdd(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "", //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                cep: cep
            }),
        };
         
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/unidade/pesquisaCEP`, requestOptions).then(
            function(response) {
            if (response.ok) {
                response.json().then(function(data) {
                    setUnit( {...unit, zipcode: data.zipcode, state: estados.filter(option => option.value === data.state)[0], street: data.street, addressComplement: data.addressComplement, neighborhood: data.neighborhood, city: data.city})
                    setLoadingAdd(false);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    data.message = data.messages ? data.messages[0].message : data.message
                    if (!!data.errorCode && !!data.message) {
                        // inserir a mensagem: data.message no aviso de erro
                        setMessageError(data.message);
                        setModalError(true);
                    } else {
                        // inserir mensagem genérica
                        setMessageError('Houve algum problema no servidor.');
                        setModalError(true);
                        console.log('Response error in /gestao/unidade/pesquisaCEP BackendBalcao');
                    }

                    setLoadingAdd(false);
                });
            } else if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoadingAdd(false)
                  setModalInvalidSessionError(true)
                })
            } else {
                setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                setModalError(true);
                setLoadingAdd(false);
            }
        }).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    const onSubmitCPF = async(cpf) => {
        cpf = removeInputMask(cpf);
        setModalError(false);
        setLoadingAdd(true)
        setLoadingIncludeGestor(true);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : "",  //Neste caso, o cpf_atendente se refere ao gestor que está cadastrando (que está atendendo)
                cpf_gestor_regional: cpf,
                codigo_orgao: unit.organCode,
                codigo_unidade: unit.unitCode
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/unidade/consultaGestorRegionalAVincularUnidade`, requestOptions).then(
            function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let users = userList;
                        users.unshift(data);
                        setUserList(users);
                        setTotalFilter(parseInt(totalFilter) + 1);
                        setLoadingAdd(false);
                        setLoadingIncludeGestor(false);
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            // inserir a mensagem: data.message no aviso de erro
                            setModalError(true);
                            setMessageError(data.message);
                        } else {
                            // inserir mensagem genérica
                            setModalError(true);
                            setMessageError('Houve algum problema no servidor.');
                        }
                        setLoadingAdd(false);
                        setLoadingIncludeGestor(false);
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setLoadingAdd(false);
                      setLoadingIncludeGestor(false)
                      setModalInvalidSessionError(true)
                    })
                } else {
                    setMessageError('Houve algum problema no servidor. Aguarde um momento e tente novamente.');
                    setModalError(true);
                    setLoadingAdd(false);
                    setLoadingIncludeGestor(false);
                }
            }
        ).catch(function(err) {
            console.error('Failed retrieving information', err);
        });
    }

    const onChangePaginationConfig = async (ranPag, pag, isRanPag) => {
        setMessageError(undefined);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_unidade: props.location.state.result_search.CODIGO_UNIDADE,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/unidade/listaGestoresRegionaisAssociadosAUnidade`,
                    requestOptions
            ).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                        let list = []
                        let obj = {}
                        for(var i = 1; i <= maxPageOption; i++) {
                            obj = {
                                value: String(i),
                                label: String(i)
                            }

                            list.push(obj);
                        }

                        setPageOptions(list);
                        if(isRanPag){
                            setPage({label: "1", value: "1"});
                        }
                        setUserList(data.usuarios);
                        setTotalFilter(data.total);
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            setModalError(data.message)
                            setMessageError(data.message)
                        } else {
                            setMessageError('Houve algum problema no servidor.');
                        }
                        //setLoading(false);
                    });
                } else if (response.status === 403) {
                    response.json().then(function (data) {
                    setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                    setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                    setModalInvalidSessionError(true)
                    })
                } else {
                    // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                    //setLoading(false);
                    console.log('Request error in gestao/listaUsuariosAssociadosAUnidade BackendBalcao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
    };

    
    return (
        <>
            <ModalWarn
                id='modalWarnalertInvalidSession'
                appear={modalInvalidSessionError}
                title = { modalInvalidSessionErrorTitle }
                text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
                textButton2='Ir para tela inicial'
                actionButton2={() => { 
                    document.getElementById("btnLogout").click();
                }}
            />
            <UnitElement
                pageTitle='Cadastrar unidade'
                onSubmitData={onSubmitUnitData}
                pesquisaCEP={pesquisaCEP}
                unit={unit}
                setUnit={setUnit}
                stateOptions={stateOptions}
                loadingAdd={loadingAdd}
                setLoading={setLoadingAdd}
                modalError ={modalError}
                setModalError={setModalError}
                setMessageError={setMessageError}
                messageError={messageError}
                buttonCancelRoute='/gerenciarUnidades'
                history={props.history}

                onChangePaginationConfig={onChangePaginationConfig}
                onSubmitCPF={onSubmitCPF}
                setLoadingIncludeGestor={setLoadingIncludeGestor}
                loadingIncludeGestor={loadingIncludeGestor}
                searchResult={userList}
                setSearchResult={setUserList}
                totalFilter={totalFilter}
                pageOptions={pageOptions}
                setPage={setPage}
                page={page}
                rangePages={rangePages}
                setRangePages={setRangePages}
            />
        </>
    );
}