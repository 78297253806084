import styled from 'styled-components';

export const Title = styled.h1 `
    width: 790px;

    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
        margin-right: 30px;
    }
`;

export const Text = styled.label `
    font-size: 15px;
    font-weight: normal;
    color: #333333;
`;

export const GlobalContainer = styled.div `
    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const TitleContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
`;

export const ContentContainer = styled.div `
    display: flex;
    justify-content: center;
    max-width: 790;
    margin: 0px auto;
`;

export const ContentContainerBlock8020 = styled.div `
    display: grid;
    grid-template-columns: 80% 20%; /* Define duas colunas de tamanho igual */
    grid-template-rows: auto auto; /* Define duas linhas */
    gap: 10px; /* Espaçamento entre os itens */
`;

export const ContentContainerBlock6040 = styled.div `
    display: grid;
    grid-template-columns: 60% 40%; /* Define duas colunas de tamanho igual */
    grid-template-rows: auto auto; /* Define duas linhas */
    gap: 10px; /* Espaçamento entre os itens */
`;

export const ContentContainer1 = styled.div `
    width: 45%;
    margin-left: 10px;
    @media (max-width: 1100px) {
        width: 100%;
        margin-left: 10px;
    }
    padding: 0px 0px 15px;
`;

export const ContentContainer11 = styled.div `
    display: grid;
    margin-bottom: 20px;
`;

export const ContentContainer12 = styled.div `
    display: grid;
    margin-top: 2px;
    margin-bottom: 20px;
    border: 1px solid #888;
    border-radius: 6px;
`;

export const ContentContainer13 = styled.div `
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
`;

export const ContentContainer14 = styled.div `
    margin-right: 60%;
    margin-bottom: 20px;
`;

export const ContentContainer15 = styled.div `
    margin-bottom: 20px;
`;

export const SelectContainer = styled.div `
    width: 30%;
    height: 64px;
    display: grid;

    //border: 1px solid;
    //border-color: red;
`;

export const ContentContainer2 = styled.div `
    width: 380px;
`;

export const ContentContainerButtons = styled.div `
    padding: 20px 0px 20px;
    text-align: right;

    button:nth-child(2){ /*-pegando o segundo button desta div-*/
        margin-left: 10px;
    }
`;

export const ContentContainerButtonsWithMarginLeft = styled.div `
    padding: 20px 0px 20px;
    text-align: right;
    margin-left: 5em;

    
`;

export const ContainerLoad = styled.div `
    
    position: absolute;
    z-index: 9999;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    #load {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -44px;
        margin-left: -22px;
    }

`;

export const ContentContainer4 = styled.div `
    margin: 20px auto;
    max-width: 790px; 
`;

export const Subtitle = styled.div `
    padding: 0px 0px 0px;
    margin-bottom: 15px;
    
    label {
       font-size: 17px;
    }
`;

export const PaginationContainer = styled.div `
    margin-top: 20px;
    padding: 0px 30px;

    //border: 1px solid;
    //border-color: red;
`;

export const SelectPagesContainer = styled.div `
    width: 55px;
    height: 64px;
    display: grid;

    //border: 1px solid;
    //border-color: red;
`;