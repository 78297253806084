import React, { useEffect, useState } from 'react';

import { removeInputMask } from '../../utils';

import ModalWarn from '../../Components/Modal/Warn/index'

import Management from '../../Components/Management'

export default function AttendantManagement(props) {
    const [ loading, setLoading] = useState(false);
    const [ organOptions, setOrganOptions ] = useState([]);
    const [ unidadeOptions, setUnidadeOptions ] = useState([]);
    const [ erroMsg, setErroMsg ] = useState('');
    const [modalInvalidSessionError, setModalInvalidSessionError] = useState(false)
    const [modalInvalidSessionErrorTitle, setModalInvalidSessionErrorTitle] = useState(null)
    const [modalInvalidSessionErrorMsg, setModalInvalidSessionErrorMsg] = useState(null)

    useEffect(() => {
        async function loadOrganOptions() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    cpf_gestor: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).cpf_atendente : ""
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/consultaOrgaoGestorRegional`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        var organList = [];
                        var obj = {};

                        data.forEach((organ) => {
                            obj = {
                                value: organ.CODIGO_ORGAO,
                                label: organ.NOME_ORGAO
                            }

                            organList.push(obj);
                        });

                        setOrganOptions(organList);
                    });
                } else {
                    console.log('Error request to /gestao/consultaorgaogestor');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        loadOrganOptions();
    }, [props.location.state]);

    useEffect(() => {
        async function loadUnidadeListOptions() {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
                }),
                body: JSON.stringify({
                    codigo_orgao: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).codigo_orgao : ""
                }),
            };
            
            await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaUnidadesPorOrgao`, requestOptions).then(
                function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        var unidadesList = [{
                            label: 'Todas as unidades'
                        }];
                        var obj = {};

                        data.forEach((unidade) => {
                            obj = {
                                value: unidade.CODIGO_UNIDADE,
                                label: unidade.NOME_UNIDADE
                            }

                            unidadesList.push(obj);
                        });

                        setUnidadeOptions(unidadesList);
                    });
                } else if (response.status === 422) {
                    response.json().then(function(data) {
                        if (!!data.errorCode && !!data.message) {
                            setErroMsg(data.message);
                        } else {
                            setErroMsg('Houve algum problema no servidor.');
                        }
                        //setLoading(false);
                    });
                } 
                else 
                if (response.status === 403) {
                    response.json().then(function (data) {
                      setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                      setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                      setLoading(false)
                      setModalInvalidSessionError(true)
                    })
                } else {
                    console.log('Error request to /gestao/listaUnidadesPorOrgao');
                }
            }).catch(function(err) {
                console.error('Failed retrieving information', err);
            });
        }

        loadUnidadeListOptions();
    }, [props.location.state]);

    const [ rangePages, setRangePages ] = useState({
        label: "10", value: "10"
    });
    const [ pageOptions, setPageOptions ] = useState([
        /*
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        */
    ]);
    const [ page, setPage ] = useState({
        label: "1", value: "1"
    });

    const [ modalError, setModalError ] = useState(false);

    const [ attendantList, setAttendantList ] = useState([
        /*
        {
            NOME_USUARIO: "Bernadete Carvalho Dantas",
            CPF_USUARIO: "22222222222",
            CODIGO_ORGAO: "1",
            NOME_ORGAO: "INSS",
            DATA_INCLUSAO: "2021-07-08T20:46:02.590Z",
            DATA_CANCELAMENTO: ""
        },
        {
            NOME_USUARIO: "Daniela Epifania Ferreira",
            CPF_USUARIO: "33333333333",
            CODIGO_ORGAO: "1",
            NOME_ORGAO: "INSS",
            DATA_INCLUSAO: "2021-07-08T20:46:02.590Z",
            DATA_CANCELAMENTO: ""
        },
        {
            NOME_USUARIO: "Gilberto Heleno Ignacio",
            CPF_USUARIO: "44444444444",
            CODIGO_ORGAO: "2",
            NOME_ORGAO: "Ministério do Trabalho",
            DATA_INCLUSAO: "2021-07-08T20:46:02.590Z",
            DATA_CANCELAMENTO: "2021-07-08T20:46:02.590Z"
        }
        */
    ]);

    const [ totalFilter, setTotalFilter ] = useState("0");

    const onSubmitSearch = async (data) => {
        setLoading(true);
        setErroMsg('');
        data.cpf = removeInputMask(data.cpf);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_orgao: data.organ,
                codigo_unidade: data.unidade,
                cpf_usuario: removeInputMask(data.cpf),
                nome_usuario: data.name,
                pagina_atual: "1",
                qtd_registros: rangePages.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaatendente`, requestOptions).then(function(response) {
            if (response.ok) {
                response.json().then(function(resp) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    const maxPageOption = Math.ceil(Number(resp.total) / Number(rangePages.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }

                    setPageOptions(list);
                    setPage({label: "1", value: "1"});
                    setAttendantList(resp.atendentes);
                    setTotalFilter(resp.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                    //setLoading(false);
                });
            } 
            else 
            if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoading(false)
                  setModalInvalidSessionError(true)
                })
            }
            else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                //setLoading(false);
                setModalError(true);
                console.log('Request error in gestao/listaatendente BackendBalcao');
            }
            setLoading(false);
        }).catch(function(err) {
            setLoading(false);
            console.error('Failed retrieving information', err);
        });
    };

    const onChangePaginationConfig = async (data, ranPag, pag, isRanPag) => {
        setErroMsg('');
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'authorization': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : ""
            }),
            body: JSON.stringify({
                codigo_orgao: data.organ,
                codigo_unidade: data.unidade,
                cpf_usuario: data.cpf,
                nome_usuario: data.name,
                pagina_atual: isRanPag ? "1" : pag.value,
                qtd_registros: ranPag.value
            }),
        };
        await fetch(`${process.env.REACT_APP_API_URL}/gestao/listaatendente`, requestOptions).then(function(response) {
            setLoading(false);
            if (response.ok) {
                response.json().then(function(data) {
                    // CONSUMIU O BACKEND BALCAO COM SUCESSO !!!!

                    const maxPageOption = Math.ceil(Number(data.total) / Number(ranPag.value))
                    let list = []
                    let obj = {}
                    for(var i = 1; i <= maxPageOption; i++) {
                        obj = {
                            value: String(i),
                            label: String(i)
                        }

                        list.push(obj);
                    }

                    setPageOptions(list);
                    if(isRanPag){
                        setPage({label: "1", value: "1"});
                    }
                    setAttendantList(data.atendentes);
                    setTotalFilter(data.total);
                });
            } else if (response.status === 422) {
                response.json().then(function(data) {
                    if (!!data.errorCode && !!data.message) {
                        setErroMsg(data.message);
                    } else {
                        setErroMsg('Houve algum problema no servidor.');
                    }
                });
            } 
            if (response.status === 403) {
                response.json().then(function (data) {
                  setModalInvalidSessionErrorTitle(data.title ? data.title : null)
                  setModalInvalidSessionErrorMsg(data.message ? data.message : null)
                  setLoading(false)
                  setModalInvalidSessionError(true)
                })
            }
            else {
                // FALHOU AO CONSUMIR O BACKEND DO BALCAO !!!!
                setModalError(true);
                console.log('Request error in gestao/listaatendente BackendBalcao');
            }
        }).catch(function(err) {
            setLoading(false);
            console.error('Failed retrieving information', err);
        });
    };

    return(
    <>
        <ModalWarn
            id='modalWarnalertInvalidSession'
            appear={modalInvalidSessionError}
            title = { modalInvalidSessionErrorTitle }
            text={ modalInvalidSessionErrorMsg ? modalInvalidSessionErrorMsg : 'Este acesso foi transferido para outro dispositivo.'}
            textButton2='Ir para tela inicial'
            actionButton2={() => { 
            document.getElementById("btnLogout").click();
            }}
        />
       <Management
            pageTitle='Gestão de Atendentes'
            buttonRegistrationTitle='+ Cadastrar Atendente'
            buttonRegistrationRoute='/consultaratendente'
            fieldNamePlaceholder='Digite o nome do atendente'
            tableResultListTitle='Lista de Atendentes Cadastrados'
            buttonDetailRoute='/detalharatendente'
            buttonEditRoute='/editaratendente'
            buttonCancelRoute='/cancelaratendente'
            buttonNextLoading={loading}
            organOptions={organOptions}
            onSubmitSearch={onSubmitSearch}
            onChangePaginationConfig={onChangePaginationConfig}
            unidadeOptions={unidadeOptions}
            searchResult={attendantList}
            setSearchResult={setAttendantList}
            history={props.history}
            totalFilter={totalFilter}
            pageOptions={pageOptions}
            setPage={setPage}
            page={page}
            erroMsg={erroMsg}
            setErroMsg={setErroMsg}
            rangePages={rangePages}
            setRangePages={setRangePages}
            modalError={modalError}
            setModalError={setModalError}
       />
    </>
    );
}