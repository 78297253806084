import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
    GlobalContainer,
    ContentContainer,
    TitleContainer,
    Title,
    Subtitle,
    Line,
    SearchContainer,
    SelectPagesContainer,
    InputContainer,
    SearchButtonsContainer,
    PaginationContainer,
} from './styles';
import Select, { components } from 'react-select';
import ModalCancel from '../Modal/Cancel/index';
import DropDownIcon from '../../assets/drop_down_icon.png';
import DropUpIcon from '../../assets/drop_up_icon.png';
import DropRightIcon from '../../assets/drop_right_icon.png';
import DropLeftIcon from '../../assets/drop_left_icon.png';
import FailIcon from '../../assets/fail_icon.png';
import ViewIcon from '../../assets/eye_icon.png';
import EditIcon from '../../assets/edit_icon.png';
import TrashIcon from '../../assets/trash2_icon.png';
import FilterIcon from '../../assets/filter_icon.png';
import AlertIcon from '../../assets/alert_icon2.png';
import ModalWarn from '../Modal/Warn/index';
import MessageSuccess from '../Messages/Success/index';
import { useLocation } from 'react-router-dom';

export default function ManagementUnit(props) {
    const location = useLocation();
    const [defaultFormValues, setDefaultFormValues] = useState({
        nome_unidade: "",
    });
    const [stateMsg, setStateMsg] = useState();
    let timeout = useRef(null) //Permite que o valor do temporizador persista entre as renderizações

    useEffect(() => {
            setDefaultFormValues({...defaultFormValues, organ: JSON.parse(localStorage.getItem('user')).codigo_orgao ? JSON.parse(localStorage.getItem('user')).codigo_orgao : "0"});
            const { message } = location.state || {}; // Desestruturar o estado recebido
            setStateMsg(message)
            location.state = {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => { //Submete a pesquisa 1 segundo após o termino do input
        clearTimeout(timeout.current);
        if (defaultFormValues.nome_unidade.trim() !== ""){
            timeout.current = setTimeout(() => {
                document.getElementById('searchButton').click();
            }, 1000)
        }       
    }, [defaultFormValues])
    
    const { handleSubmit, errors } = useForm({ defaultFormValues });

    const [rangePagesOptions] = useState([
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
    ]);

    // ESTILIZACAO PARA O SELECT ======================================================
    const SelecPageStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderWidth: 0,
            borderColor: state.isFocused ? '#E60000' : '#888',
            '&:hover': {
                backgroundColor: '#C5D4EB',
            },
            marginTop: 15,
        }),
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen 
                    ? <img src={DropUpIcon} alt="upicon" />
                    : <img src={DropDownIcon} alt="downicon"/>
                }
            </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ({ innerProps }) => {
        return <span style={{}} {...innerProps} />;
    };
    // ================================================================================ 

    const [modalCancel, setModalCancel] = useState(false);

    return (
        <>
            <ModalCancel
                id="modalcancelalert"
                appear={modalCancel}
                text={"Deseja cancelar a pesquisa e retornar para página principal?"}
                actionButton1={() => setModalCancel(false)}
                actionButton2={() => props.history.push("/pesquisarcidadao")}
            />
            <ModalWarn
                id="modalconfirmalert"
                appear={props.modalError}
                text = {
                    'Houve algum problema no servidor. Aguarde um momento e tente novamente.'
                }
                textButton2='Ok'
                actionButton2={() => props.setModalError(false)}
            />


            <GlobalContainer>
                {stateMsg &&
                    <MessageSuccess text={stateMsg}/>
                }
                <ContentContainer>
                    <TitleContainer>
                        <Title>{props.pageTitle}</Title>
                        <button className="br-button primary" onClick={() => props.history.push(props.buttonRegistrationRoute)}>
                            {props.buttonRegistrationTitle}
                        </button>
                    </TitleContainer>

                    <Subtitle>
                        <img
                            src={FilterIcon}
                            alt="filterIcon"
                            style={{ "marginBottom": "7px", "marginRight": "7px" }}
                        />
                        <label> Filtro </label>
                        <Line />
                    </Subtitle>

                    <form onSubmit={handleSubmit(() => {setStateMsg(undefined); props.onSubmitSearch(defaultFormValues);})}>
                        <SearchContainer>
                            <InputContainer>
                                <div className="br-input">
                                    <label htmlFor="nomeUnidade"> Nome da unidade do órgão </label>
                                    <input style={{"width": "300px"}}
                                        id="nomeUnidade"
                                        name="nomeUnidade"
                                        nome_unidade="nome_unidade"
                                        type="text"
                                        placeholder={props.fieldNamePlaceholder}
                                        defaultValue={defaultFormValues.nome_unidade}
                                        onChange={(event) => {
                                            const { value } = event.target
                                            setDefaultFormValues({ ...defaultFormValues, nome_unidade: value })
                                            props.setSearchResult([]);
                                            props.setErroMsg(undefined);
                                        }}
                                    />
                                </div>
                                {errors?.nomeUnidade &&
                                    <span className="feedback danger" role="alert">
                                        <i className="fas fa-times-circle" aria-hidden="true">
                                            <img
                                                src={FailIcon}
                                                style={{ paddingBottom: '5px' }}
                                                alt="failicon"
                                            />
                                        </i>
                                        {errors.nomeUnidade.message}
                                    </span>}
                            </InputContainer>                        
                        </SearchContainer>

                        <SearchButtonsContainer>
                            <button className="br-button secundary" type="button"
                                onClick={() => setModalCancel(true)}>
                                Cancelar
                            </button>
                            <button className="br-button secondary" type="button"
                                onClick={() => {
                                    setDefaultFormValues({...defaultFormValues, nome_unidade: ""})
                                    props.setSearchResult([]);
                                    setDefaultFormValues({ ...defaultFormValues, nome_unidade: '' })
                                    document.getElementById('nomeUnidade').value = "";
                                    props.setErroMsg(undefined);
                                }}>
                                Limpar Filtro
                            </button>
                            {!props.buttonNextLoading ?
                                <button id='searchButton' className="br-button primary" type="submit">
                                    Pesquisar
                                </button> 
                            :
                                <button id='searchButton' className="br-button primary loading" type="button">
                                    Pesquisar
                                </button>
                            }
                        </SearchButtonsContainer>

                        <Subtitle>
                            <Line />
                        </Subtitle>
                    </form>

                    {!!props.searchResult.length && <>
                        <Subtitle>
                            <label>{props.tableResultListTitle}</label>
                        </Subtitle>
                        <div className="br-table" data-search="data-search" data-selection="data-selection">
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col0" style={{ 'color': '#1351B4' }}></th>
                                        <th scope="col1" style={{ 'color': '#1351B4' }}> Nome da Unidade </th>
                                        <th scope="col2" style={{ 'color': '#1351B4' }}> Estado </th>
                                        <th scope="col3" style={{ 'color': '#1351B4' }}> Cidade/Município </th>
                                        <th scope="col4" style={{ 'color': '#1351B4' }}> Endereço </th>
                                        <th scope="col8" style={{ 'color': '#1351B4' }}> Ações </th>
                                        <th scope="col99" style={{ 'color': '#1351B4' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.searchResult.map(result => (
                                        <tr key={result.CODIGO_UNIDADE}>
                                            <td> </td>
                                            <td> {result.NOME_UNIDADE} </td>
                                            <td> {result.ESTADO ? result.ESTADO : '-'}</td>
                                            <td> {result.CIDADE ? result.CIDADE : '-'}</td>
                                            <td> {result.LOGRADOURO ? result.LOGRADOURO : ''} {result.NUMERO ? ', ' + result.NUMERO : ''} {' - '} {result.BAIRRO ? result.BAIRRO : ''} {result.COMPLEMENTO ? ', ' + result.COMPLEMENTO : ''} {result.CEP ? ', ' + result.CEP : ''}</td>

                                            <td style={{width: "100px"}}>
                                                <img
                                                    src={ViewIcon}
                                                    alt="viewIcon"
                                                    title="Detalhar"
                                                    style={{
                                                        'marginRight': '15px',
                                                        'cursor': 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        const data = {
                                                            result_search: result,
                                                            pagination: {
                                                                range_page: props.rangePages,
                                                                page: props.page,
                                                                page_options: props.pageOptions,
                                                                total: props.totalFilter
                                                            },
                                                            list: props.searchResult
                                                        }
                                                        props.history.push(props.buttonDetailRoute, data);
                                                    }}
                                                />
                                                <img
                                                    src={EditIcon}
                                                    alt="editIcon"
                                                    title="Editar"
                                                    style={{
                                                        'marginRight': '10px',
                                                        'cursor': 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        const data = {
                                                            result_search: result,
                                                            pagination: {
                                                                range_page: props.rangePages,
                                                                page: props.page,
                                                                page_options: props.pageOptions,
                                                                total: props.totalFilter
                                                            },
                                                            list: props.searchResult
                                                        }
                                                        props.history.push(props.buttonEditRoute, data);
                                                    }}
                                                />
                                                <img
                                                    src={TrashIcon}
                                                    alt="trashIcon"
                                                    title="Remover"
                                                    style={{
                                                        'cursor': 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        const data = {
                                                            result_search: result,
                                                            fields_search: {
                                                                codigo_orgao: result.codigo_orgao,
                                                                codigo_unidade: result.codigo_unidade
                                                            },
                                                            pagination: {
                                                                range_page: props.rangePages,
                                                                page: props.page,
                                                                page_options: props.pageOptions,
                                                                total: props.totalFilter
                                                            },
                                                            list: props.searchResult
                                                        }
                                                        props.history.push(props.buttonDeleteRoute, data);
                                                    }}
                                                />
                                            </td>                                   
                                            <td> </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <PaginationContainer>
                            <nav className="br-pagination" aria-label="Paginação de resultados" data-total="50" data-current="1" data-per-page="20">
                                <div className="pagination-per-page">
                                    <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Exibir </label>
                                    <SelectPagesContainer>
                                        <Select
                                            options={rangePagesOptions}
                                            defaultValue={{ value: "10", label: "10" }}
                                            value={props.rangePages}
                                            onChange={value => {
                                                props.setRangePages(value)
                                                props.onChangePaginationConfig(defaultFormValues, value, props.page, true)
                                            }}
                                            styles={SelecPageStyles}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 6,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#CCCCCC',
                                                    primary: '#1351B4'
                                                },
                                            })}
                                            components={
                                                {
                                                    DropdownIndicator,
                                                    IndicatorSeparator
                                                }
                                            }
                                        />
                                    </SelectPagesContainer>
                                </div>

                                <span className="br-divider d-none d-sm-block mx-3"></span>

                                <div className="pagination-information d-none d-sm-flex">
                                    <span className="current"> {1 + ((Number(props.page.value) - 1) * Number(props.rangePages.value))} </span>&ndash;<span className="per-page"> {Number(props.page.value) * Number(props.rangePages.value)} </span>&nbsp;de&nbsp;<span className="total"> {props.totalFilter} </span>&nbsp;itens
                                </div>
                                <div className="pagination-go-to-page d-none d-sm-flex ml-auto">
                                    <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Página </label>
                                    <SelectPagesContainer>
                                        <Select
                                            options={props.pageOptions}
                                            defaultValue={{ value: "1", label: "1" }}
                                            value={props.page}
                                            onChange={value => {
                                                props.setPage(value)
                                                props.onChangePaginationConfig(defaultFormValues, props.rangePages, value, false)
                                            }}
                                            styles={SelecPageStyles}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 6,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#CCCCCC',
                                                    primary: '#1351B4'
                                                },
                                            })}
                                            components={
                                                {
                                                    DropdownIndicator,
                                                    IndicatorSeparator
                                                }
                                            }
                                        />
                                    </SelectPagesContainer>
                                </div>

                                <span className="br-divider d-none d-sm-block mx-3"></span>

                                <div className="pagination-arrows ml-auto ml-sm-0">
                                    <button className="br-button circle" type="button" aria-label="Voltar página"
                                        style={{
                                            'opacity': Number(props.page.value) > 1 ? '' : '0.4',
                                            'pointerEvents': Number(props.page.value) > 1 ? '' : 'none'
                                        }}
                                        onClick={() => {
                                            const new_page = {
                                                value: String(Number(props.page.value) - 1),
                                                label: String(Number(props.page.value) - 1)
                                            }
                                            props.setPage(new_page)
                                            props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                                        }}
                                    >
                                        <i className="fas fa-angle-left" aria-hidden="true">
                                            <img
                                                src={DropLeftIcon}
                                                alt="leftIcon"
                                                style={{ "marginBottom": "6px" }}
                                            />
                                        </i>
                                    </button>
                                    <button className="br-button circle" type="button" aria-label="Avançar página"
                                        style={{
                                            'opacity': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : '0.4',
                                            'pointerEvents': Number(props.page.value) < (Math.ceil(Number(props.totalFilter) / Number(props.rangePages.value))) ? '' : 'none'
                                        }}
                                        onClick={() => {
                                            const new_page = {
                                                value: String(Number(props.page.value) + 1),
                                                label: String(Number(props.page.value) + 1)
                                            }
                                            props.setPage(new_page)
                                            props.onChangePaginationConfig(defaultFormValues, props.rangePages, new_page, false)
                                        }}
                                    >
                                        <i className="fas fa-angle-right" aria-hidden="true">
                                            <img
                                                src={DropRightIcon}
                                                alt="rightIcon"
                                                style={{ "marginBottom": "6px" }}
                                            />
                                        </i>
                                    </button>
                                </div>
                            </nav>
                        </PaginationContainer>
                    </>}

                    {
                        props.erroMsg &&
                            <span className="feedback warning" role="alert" id="listresult">
                                <i className="fas fa-times-circle" aria-hidden="true">
                                    <img
                                        src={AlertIcon}
                                        style={{ paddingBottom: '5px' }}
                                        alt="failicon"
                                    />
                                </i>
                                <label id="listresultlabel">{props.erroMsg}</label>
                            </span>
                    }
                </ContentContainer>
            </GlobalContainer>
        </>
    );
}