import styled from 'styled-components';

export const Title = styled.h1 `
    width: 790px;

    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 18px;
        width: 380px;
        margin-right: 30px;
    }
`;

export const GlobalContainer = styled.div `

    @media (max-width: 1000px) {
        div {
            display: grid;
        }
    }

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const TitleContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
    justify-content: center;
`;

export const ContentContainer = styled.div `
    display: flex;
    justify-content: center;
    max-width: 790;
    margin: 0px auto;
`;

export const ContentContainer1 = styled.div `
    width: 380px;
    margin-right: 30px;
    padding: 0px 0px 15px;
`;

export const ContentContainer11 = styled.div `
    display: grid;
    margin-bottom: 20px;
`;

export const ContentContainer2 = styled.div `
    width: 380px;
`;

export const ContentContainerButtons = styled.div `
    padding: 20px 0px 20px;
    text-align: right;

    button:nth-child(2){ /*-pegando o segundo button desta div-*/
        margin-left: 10px;
    }
`;