import React from 'react'

import { ModalCenterFont } from './styles'
//import '../../../../node_modules/@govbr/dsgov/dist/dsgov.min.css';;

function ModalAddGestorRegional({ appear, text, actionButton2, title, history, location }) {
  return (
    <ModalCenterFont appear={appear}>
      <div className='br-modal is-medium' id='modal'>
        <div className='br-card'>
          <div className='container-fluid p-4'>
            {
              title && <div className="br-modal-header">
                          <div className="br-modal-title">
                              <strong>{title}</strong>
                          </div>
                      </div>
            }
            <div className='br-modal-body'>{text}</div>
            <div className='br-modal-footer justify-content-center'>
              <button className='br-button primary small m-2' type='button' onClick={actionButton2}>
                Ok
              </button>
              {/* {location?.pathname === '/editarUnidade' &&
                <button className="br-button primary small m-2" type='button' onClick={() => history.push('/consultaGestorRegional')}>
                    Cadastrar Gestor Regional
                </button>
              } */}
            </div>
          </div>
        </div>
      </div>
    </ModalCenterFont>
  )
}

export default ModalAddGestorRegional
