import React from 'react'
import { useLocation } from 'react-router-dom'

import {
  GlobalContainer,
  ContentContainer,
  ContentContainer11,
  ContentContainer3,
  ContentContainer4,
  ContentContainerTable,
  ContentContainerDown,
  Title,
  Text,
  TitleContainer,
  ContentContainerButtons,
} from './styles'

import ModalSuccess from '../../Components/Messages/Success/index'
import MessageError from '../../Components/Messages/Error'
import { cpfMask, formatDate } from '../../utils'

export default function AgencyManagerScheduleDetailsManagement(props) {
  let location = useLocation()

  const dias = [
    { label: 'Seg à Sex', value: 'Seg à Sex' },
    { label: 'Seg a Sáb', value: 'Seg a Sáb' },
    { label: 'Seg a Dom', value: 'Seg a Dom' },
    { label: 'Sábado', value: 'Sábado' },
    { label: 'Domingo', value: 'Domingo' },
    { label: 'Final de Semana', value: 'Final de Semana' },
  ]

  return (
    <>
      {!props.loadOperator ? (
        <div style={{ marginTop: '75px' }}>
          <h1> Error on the page! </h1>
        </div>
      ) : (
        <>
          <GlobalContainer>
            {props.fromSuccessMsg && <ModalSuccess text={props.fromSuccessMsg} />}
            {props.erroMsg?.length > 0 && <MessageError show={true} text={props.erroMsg} />}

            <ContentContainer4>
              <TitleContainer>
                <Title> {props.pageTitle} </Title>
              </TitleContainer>
            </ContentContainer4>
            <ContentContainer>
              <ContentContainer4>
                <ContentContainer11>
                  <label> CPF </label>
                  <Text>{cpfMask(props.regionalManager.cpf)}</Text>
                </ContentContainer11>
                <ContentContainer11>
                  <label> Nome </label>
                  <Text>{props.regionalManager.name}</Text>
                </ContentContainer11>
                <ContentContainer11>
                  <label> Unidade(s) </label>
                  {props.regionalManager.units && props.regionalManager.units.map(unit => (
                      <Text>- {unit.label}</Text>
                      ))
                  }
                </ContentContainer11>
              </ContentContainer4>
            </ContentContainer>

            <ContentContainer4>
              <ContentContainer11>
                <label>Horário do órgão:</label>
                <Text>
                  {props.orgaoSchedule.dias_semana}, {props.orgaoSchedule.hora_inicio} às {props.orgaoSchedule.hora_fim} (Horário de Brasília)
                </Text>
              </ContentContainer11>
            </ContentContainer4>

            <ContentContainer3>
              <ContentContainerTable>
                <div className='br-table' data-search='data-search' data-selection='data-selection'>
                  <table>
                    <thead>
                      <tr>
                        <th scope='col0' style={{ color: '#1351B4' }}></th>
                        <th scope='col2' style={{ color: '#1351B4' }}>
                          Dias da semana
                        </th>
                        <th scope='col3' style={{ color: '#1351B4' }}>
                          Horário Inicial
                        </th>
                        <th scope='col3' style={{ color: '#1351B4' }}>
                          Horário Final
                        </th>
                        <th scope='col4' style={{ color: '#1351B4' }}>
                          Data limite
                        </th>
                        <th scope='col99' style={{ color: '#1351B4' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> </td>
                        <td>
                          {location.pathname === '/editarHorarioOrgao' ? (
                            <select value={props.regionalManager.dias_semana} onChange={(e) => props.setRegionalManager({ ...props.regionalManager, dias_semana: e.target.value })}>
                              {dias.map((d) => (
                                <option key={d.value} value={d.value}>
                                  {d.label}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <label>{props.regionalManager.dias_semana}</label>
                          )}
                        </td>
                        <td>
                          {location.pathname === '/editarHorarioOrgao' ? (
                            <input
                              type='time'
                              readOnly={location.pathname === '/detalharHorarioOrgao'}
                              defaultValue={props.regionalManager.hora_inicio}
                              onChange={(e) => props.setRegionalManager({ ...props.regionalManager, hora_inicio: e.target.value })}
                            />
                          ) : (
                            <label>{props.regionalManager.hora_inicio}</label>
                          )}
                        </td>
                        <td>
                          {location.pathname === '/editarHorarioOrgao' ? (
                            <input
                              type='time'
                              readOnly={location.pathname === '/detalharHorarioOrgao'}
                              defaultValue={props.regionalManager.hora_fim}
                              onChange={(e) => props.setRegionalManager({ ...props.regionalManager, hora_fim: e.target.value })}
                            />
                          ) : (
                            <label>{props.regionalManager.hora_fim}</label>
                          )}
                        </td>
                        <td>
                          {location.pathname === '/editarHorarioOrgao' ? (
                            <input
                              type='date'
                              readOnly={location.pathname === '/detalharHorarioOrgao'}
                              defaultValue={props.regionalManager.data_limite}
                              onChange={(e) => props.setRegionalManager({ ...props.regionalManager, data_limite: e.target.value })}
                            />
                          ) : (
                            <label>{formatDate(props.regionalManager.data_limite) || '-'}</label>
                          )}
                        </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ContentContainerTable>
            </ContentContainer3>

            <ContentContainer>
              <ContentContainer4>
                <ContentContainer11>
                  <label> Justificativa </label>
                  {location.pathname === '/editarHorarioOrgao' ? (
                    <textarea
                      rows='4'
                      readOnly={location.pathname === '/detalharHorarioOrgao'}
                      defaultValue={props.regionalManager.motivo}
                      onChange={(e) => props.setRegionalManager({ ...props.regionalManager, motivo: e.target.value })}></textarea>
                  ) : (
                    <p>{props.regionalManager.motivo || '-'}</p>
                  )}
                </ContentContainer11>
              </ContentContainer4>
            </ContentContainer>

            <ContentContainerDown>
              <ContentContainerButtons>
                <button className='br-button' type='button' onClick={() => props.history.push(props.buttonBackRoute)}>
                  Voltar
                </button>

                {location.pathname === '/editarHorarioOrgao' &&
                  <button
                    className={`br-button primary ${props.loadingButton ? ' loading ' : ''} `}
                    type='button'
                    onClick={() => {
                      props.confirmUpdate()
                    }}>
                    Confirmar
                  </button>
                }
              </ContentContainerButtons>
            </ContentContainerDown>
          </GlobalContainer>
        </>
      )}
    </>
  )
}
