import React, { useState } from 'react'

import { ModalCenterFont, SelectContainer, } from './styles'
import Select, { components } from 'react-select';
import DropDownIcon from '../../../assets/drop_down_icon.png';
import DropUpIcon from '../../../assets/drop_up_icon.png';
import FailIcon from '../../../assets/fail_icon.png';

function ModalUnitChoice({ appear, actionButton1, textButton1, title, unitListOptions, errorMessage }) {
  const [chosenUnit, setChosenUnit] = useState({value: 0, label: 'Selecione sua unidade'})
  const [error, setError] = useState(false)
  const SelecStyles = {
    control: (defaultStyles, state) => ({
        ...defaultStyles,
        borderWidth: state.isFocused ? 2.5 : 1,
        borderColor: state.isFocused ? '#E60000' : '#888',
        '&:hover': {
            backgroundColor: '#C5D4EB',
        },
        marginTop: 6,
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: 13.5,
        fontWeight: 355,
        marginLeft: 7,
        fontStyle: 'italic',
    }),
  }
  const DropdownIndicator = (props) => {
      return (
          <components.DropdownIndicator {...props}>
              {props.selectProps.menuIsOpen 
                  ? <img src={DropUpIcon} alt="upicon" />
                  : <img src={DropDownIcon} alt="downicon"/>
              }
          </components.DropdownIndicator>
      );
  };
  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={{}} {...innerProps} />;
  };
  return (
    <ModalCenterFont appear={appear}>
      <div className='br-modal is-medium' id='modal'>
        <div className='br-card'>
          <div className='container-fluid p-4'>
            {
              title && <div className="br-modal-header">
                          <div className="br-modal-title">
                              <strong>{title}</strong>
                          </div>
                      </div>
            }
            <div className='br-modal-body'>
              <div>
                  <label style={{ "fontWeight": "normal", "marginRight": "15px" }}> Unidade: </label>
                  <SelectContainer>
                      <Select
                          options={unitListOptions}
                          defaultValue={unitListOptions && unitListOptions[0]}
                          value={chosenUnit}
                          onChange={value => {
                            setChosenUnit(value)
                          }}
                          styles={SelecStyles}
                          theme={theme => ({
                              ...theme,
                              borderRadius: 6,
                              colors: {
                                  ...theme.colors,
                                  primary25: '#CCCCCC',
                                  primary: '#1351B4'
                              },
                          })}
                          components={
                              {
                                  DropdownIndicator,
                                  IndicatorSeparator
                              }
                          }
                      />
                      {error &&
                        <span className="feedback danger" role="alert" id="organresult">
                            <i className="fas fa-times-circle" aria-hidden="true">
                                <img
                                    src={FailIcon}
                                    style={{ paddingBottom: '5px' }}
                                    alt="failicon"
                                />
                            </i>
                            {errorMessage}
                        </span>}
                  </SelectContainer>
              </div>
            </div>
            <div className='br-modal-footer justify-content-center'>
              <button className='br-button primary small m-2' type='button' id='bnt-unit-chosen' onClick={()=>{
                if (chosenUnit && chosenUnit.value !== 0) {
                  setError(false)
                  actionButton1(chosenUnit)
                } else {
                  setError(true)
                }                
              }}>
                {textButton1}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenterFont>
  )
}

export default ModalUnitChoice
