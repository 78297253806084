import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import AttorneyData from './pages/AttorneyData/attorneyData'
import SearchCitizen from './pages/SearchCitizen/searchCitizen'
import CitizenData from './pages/CitizenData/citizenData'
import ProvisionalPassword from './pages/ProvisionalPassword/provisionalPassword'
import RedirectLogin from './pages/RedirectLogin/redirectLogin'
import Initial from './pages/Initial/initial'
import AttendantManagement from './pages/AttendantManagement/attendantManagement'
import LoginError from './pages/LoginError/loginError'
import SearchAttendant from './pages/SearchAttendant/searchAttendant'
import AddAttendant from './pages/AddAttendant/addAttendant'
import AttendantDetails from './pages/AttendantDetails/attendantDetails'
import EditAttendant from './pages/EditAttendant'
import ShowAttendant from './pages/ShowAttendant/showAttendant'
import CancelAttendant from './pages/CancelAttendant/cancelAttendant'

import PrivateRoute from './credentials/privateRoute'
import PrivateManagerRoute from './credentials/privateManagerRoute'
import RegionalManagement from './pages/RegionalManagement'
import SearchRegionalManager from './pages/SearchRegionalManager'
import AddRegionalManager from './pages/AddRegionalManager'
import ShowRegionalManager from './pages/ShowRegionalManager'
import EditRegionalManager from './pages/EditRegionalManager'
import RegionalManagerDetails from './pages/RegionalManagerDetails'
import SearchRegionalManagerSubstitute from './pages/SearchRegionalManagerSubstitute'
import CancelRegionalManager from './pages/CancelRegionalManager'
import ReplaceAndCancelRegionalManager from './pages/ReplaceAndCancelRegionalManager'
import AgencyManagement from './pages/AgencyManagement'
import SearchAgencyManager from './pages/SearchAgencyManager'
import AddAgencyManager from './pages/AddAgencyManager'
import ShowAgencyManager from './pages/ShowAgencyManager'
import EditAgencyManager from './pages/EditAgencyManager'
import AgencyManagerDetails from './pages/AgencyManagerDetails'
import CancelAgencyManager from './pages/CancelAgencyManager'
import SearchAgencyManagerSubstitute from './pages/SearchAgencyManagerSubstitute'
import ReplaceAgencyManager from './pages/ReplaceAgencyManager'
import MenuReport from './Reports/MenuReport'
import CallsByAgencyAdminReport from './Reports/admin/CallsByAgencyAdminReport'
import ManagersByAgencyAdminReport from './Reports/admin/ManagersByAgencyAdminReport'
import AttendantActiveCancelledByManagerByMonthAdminReport from './Reports/admin/AttendantActiveCancelledByManagerByMonthAdminReport'
import AttedantsSignedUpActiveCancelledByAgencyAdminReport from './Reports/admin/AttedantsSignedUpActiveCancelledByAgencyAdminReport'
import AttendantActiveCancelledByManagerByMonthAdminUnitReport from './Reports/admin/AttendantActiveCancelledByManagerByMonthAdminUnitReport'
import CallsByAgencyManagerReport from './Reports/managers/CallsByAgencyManagerReport'
import CallsByFlowManagerReport from './Reports/managers/CallsByFlowManagerReport'
import CallsByAttendantManagerReport from './Reports/managers/CallsByAttendantManagerReport'
import CallsByAttendantManagerByUnitReport from './Reports/managers/CallsByAttendantManagerByUnitReport'
import CallsByRegionalManagerReport from './Reports/managers/CallsByRegionalManagerReport'
import AttedantsSignedUpActiveCancelledManagerReport from './Reports/managers/AttedantsSignedUpActiveCancelledManagerReport'
import SearchLockUnlockUser from './pages/SearchLockUnlockUser'
import LockUnlockUser from './pages/LockUnlockUser'
import InformRegionalManagerSubstitute from './pages/InformRegionalManagerSubstitute'
import ReplaceRegionalManager from './pages/ReplaceRegionalManager'

import { profile } from './constants'
import AdminAgencySchedule from './pages/AdminAgencySchedule/AdminAgencySchedule'
import AgencyManagerSchedule from './pages/AgencyManagerSchedule'
import AttendantSchedule from './pages/AttendantSchedule'
import AttendantScheduleDetails from './pages/AttendantSchedule/AttendantScheduleDetails'
import AdminEditAgencySchedule from './pages/AdminAgencySchedule/AdminEditAgencySchedule'
import AgencyManagerScheduleDetails from './pages/AgencyManagerSchedule/AgencyManagerScheduleDetails'

import UnitManagerSchedule from './pages/UnitManagerSchedule'
import UnitManagerScheduleDetails from './pages/UnitManagerSchedule/UnitManagerScheduleDetails'
import UnitManagerScheduleCurrent from './pages/UnitManagerScheduleCurrent'

import CustomerService from './pages/CustomerService/customerService'
import ProfileManagement from './pages/ProfileManagement'
import UnitManagement from './pages/UnitManagement'
import AddUnit from './pages/AddUnit'
import EditUnit from './pages/EditUnit'
import DeleteUnit from './pages/DeleteUnit'
import UnitDetails from './pages/UnitDetails'

export default function Routes(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  const perfil = user && user.perfil
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact render={(propsr) => <Initial {...propsr} handleShowInfo={(appear) => props.handleShowInfo(appear)} />} />
        <Route
          path='/login'
          component={() => {
            const { REACT_APP_LOGIN_UNICO_URL, REACT_APP_RESPONSE_TYPE, REACT_APP_CLIENT_ID, REACT_APP_SCOPE, REACT_APP_REDIRECT_URI, REACT_APP_NONCE, REACT_APP_STATE } = process.env
            const possibleURLs = ['https://sso.staging.acesso.gov.br', 'https://sso.acesso.gov.br']
            if (possibleURLs.includes(REACT_APP_LOGIN_UNICO_URL)) {
              window.location.replace(
                `${REACT_APP_LOGIN_UNICO_URL}/authorize?response_type=${REACT_APP_RESPONSE_TYPE}&client_id=${REACT_APP_CLIENT_ID}&scope=${REACT_APP_SCOPE}&redirect_uri=${REACT_APP_REDIRECT_URI}&nonce=${REACT_APP_NONCE}&state=${REACT_APP_STATE}`
              )
            } else {
              return <h1 style={{ marginTop: '80px', width: '960px', fontSize: '24px', color: '#0c326f' }}>Error: URL não está contida na lista de possívels URLs do sistema</h1>
            }
          }}
        />
        <Route path='/logout' exact render={(propsr) => <Initial {...propsr} handleShowInfo={(appear) => props.handleShowInfo(appear)} />} />
        <Route path='/loginerror' component={LoginError} />
        <Route
          path='/pesquisar'
          render={(propsr) => (
            <RedirectLogin
              {...propsr}
              handleName={(name) => props.handleName(name)}
              handleOrgan={(organ) => props.handleOrgan(organ)}
              handlePerfil={(perfil) => props.handlePerfil(perfil)}
              handlePicture={(picture) => props.handlePicture(picture)}
              handleScrollAtivated={() => props.handleScrollAtivated()}
            />
          )}
        />
        <PrivateRoute path='/pesquisarcidadao' handleShowInfo={(appear) => props.handleShowInfo(appear)} handleScrollAtivated={() => props.handleScrollAtivated()} component={SearchCitizen} />
        <PrivateRoute path='/dadoscidadao' handleShowInfo={(appear) => props.handleShowInfo(appear)} handleScrollAtivated={() => props.handleScrollAtivated()} component={CitizenData} />
        <PrivateRoute handleShowInfo={(appear) => props.handleShowInfo(appear)} path='/dadosprocurador' handleScrollAtivated={() => props.handleScrollAtivated()} component={AttorneyData} />
        <PrivateRoute handleShowInfo={(appear) => props.handleShowInfo(appear)} path='/senhaprovisoria' handleScrollAtivated={() => props.handleScrollAtivated()} component={ProvisionalPassword} />
        <PrivateRoute handleShowInfo={(appear) => props.handleShowInfo(appear)} path='/gerenciarperfil' handleScrollAtivated={() => props.handleScrollAtivated()} component={ProfileManagement} />

        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/gerenciaratendentes'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AttendantManagement}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/consultaratendimento'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CustomerService}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/consultaratendente'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={SearchAttendant}
        />
        <PrivateManagerRoute handleShowInfo={(appear) => props.handleShowInfo(appear)} path='/adicionaratendente' handleScrollAtivated={() => props.handleScrollAtivated()} component={AddAttendant} />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/detalharatendente'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AttendantDetails}
        />
        <PrivateManagerRoute handleShowInfo={(appear) => props.handleShowInfo(appear)} path='/editaratendente' handleScrollAtivated={() => props.handleScrollAtivated()} component={EditAttendant} />
        <PrivateManagerRoute handleShowInfo={(appear) => props.handleShowInfo(appear)} path='/mostraratendente' handleScrollAtivated={() => props.handleScrollAtivated()} component={ShowAttendant} />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/cancelaratendente'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CancelAttendant}
        />
        {/**
         * GESTÃO DE GESTORES REGIONAIS
         *
         */}
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/gerenciarGestoresRegionais'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={RegionalManagement}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/consultaGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={SearchRegionalManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/adicionarGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AddRegionalManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/mostrarGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={ShowRegionalManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/editarGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={EditRegionalManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/detalharGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={RegionalManagerDetails}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/cancelarGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CancelRegionalManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/consultaGestorRegionalSubstituto'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={SearchRegionalManagerSubstitute}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/informarGestorRegionalSubstituto'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={InformRegionalManagerSubstitute}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/substituirCancelarGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={ReplaceAndCancelRegionalManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/substituirGestorRegional'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={ReplaceRegionalManager}
        />
        {/**
         * GESTÃO DE GESTORES DE ORGÃO
         *
         */}
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/gerenciarGestoresOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AgencyManagement}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/consultaGestorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={SearchAgencyManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/adicionarGestorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AddAgencyManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/mostrarGestorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={ShowAgencyManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/editarGestorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={EditAgencyManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/detalharGestorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AgencyManagerDetails}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/cancelarGestorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CancelAgencyManager}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/consultaGestorOrgaoSubstituto'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={SearchAgencyManagerSubstitute}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/substituirGestorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={ReplaceAgencyManager}
        />
        {/**
         * BLOQUEIO E DESBLOQUEIO DE USUÁRIOS
         *
         */}
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/bloquearDesbloquear'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={SearchLockUnlockUser}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/bloquearDesbloquearUsuario'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={LockUnlockUser}
        />
        {/**
         * RELATÓRIOS
         *
         */}
        <PrivateManagerRoute handleShowInfo={(appear) => props.handleShowInfo(appear)} path='/relatorios' handleScrollAtivated={() => props.handleScrollAtivated()} component={MenuReport} />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendimentosPorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CallsByAgencyAdminReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relGestoresPorOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={ManagersByAgencyAdminReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendentesPorGestorMes'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AttendantActiveCancelledByManagerByMonthAdminReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendentesPorGestorMesUnidade'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AttendantActiveCancelledByManagerByMonthAdminUnitReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendentesPorOrgaoMes'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AttedantsSignedUpActiveCancelledByAgencyAdminReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendimentosOrgao'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CallsByAgencyManagerReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendimentosUnidade'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CallsByRegionalManagerReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendimentosPorFluxo'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CallsByFlowManagerReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendimentosPorAtendente'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CallsByAttendantManagerReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendimentosPorAtendentePorUnidade'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={CallsByAttendantManagerByUnitReport}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/relAtendentesDoOrgaoPorMes'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AttedantsSignedUpActiveCancelledManagerReport}
        />
        {/**
         *
         * GESTÃO DE HORÁRIOS
         *
         */}
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          RegionalManagement
          path='/horarios'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={isAdmin() ? AdminAgencySchedule : isGestorOrgao() ? AgencyManagerSchedule : isGestorRegional ? AttendantSchedule : ''}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path={'/detalharHorarioOrgao'}
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={isAdmin() ? AdminEditAgencySchedule : isGestorOrgao() ? AgencyManagerScheduleDetails : isGestorRegional ? AttendantScheduleDetails : ''}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path={'/editarHorarioOrgao'}
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={isAdmin() ? AdminEditAgencySchedule : isGestorOrgao() ? AgencyManagerScheduleDetails : isGestorRegional ? AttendantScheduleDetails : ''}
        />

        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          RegionalManagement
          path='/horariosUnidades'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={isGestorOrgao() ? UnitManagerSchedule : isGestorRegional ? UnitManagerScheduleCurrent : ''}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path={'/detalharHorarioUnidade'}
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={UnitManagerScheduleDetails}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path={'/editarHorarioUnidade'}
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={isGestorOrgao() ? UnitManagerScheduleDetails : isGestorRegional ? UnitManagerScheduleCurrent : ''}
        />

        {/**
         * GESTÃO DE UNIDADES DO ÓRGAO
         *
         */}
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/gerenciarUnidades'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={UnitManagement}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/adicionarUnidade'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={AddUnit}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/detalharUnidade'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={UnitDetails}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/editarUnidade'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={EditUnit}
        />
        <PrivateManagerRoute
          handleShowInfo={(appear) => props.handleShowInfo(appear)}
          path='/removerUnidade'
          handleScrollAtivated={() => props.handleScrollAtivated()}
          component={DeleteUnit}
        />
      </Switch>
    </BrowserRouter>
  )

  function isGestorOrgao() {
    return perfil === profile.gestorOrgao
  }

  function isGestorRegional() {
    return perfil === profile.gestorRegional
  }

  function isAdmin() {
    return perfil === profile.gestorSistema
  }
}
