import styled from 'styled-components';

export const Title = styled.h1 `
    font-size: 24px;
    color: #0c326f;

    @media (max-width: 1000px) {
        font-size: 20px;
    }
`;

export const Subtitle = styled.div `
    padding: 0px 0px 0px;
    margin-bottom: 1em;
    text-align: center;
    label {
       font-size: 18px;
       font-weight: 400;
       color: #0c326f;       
    }
`;

export const GlobalContainer = styled.div `

    @media (max-width: 1000px) {
        div {
            margin: 0px auto;
        }
    }

    @media print {
        @page { 
            /* size: A4 landscape; */
            size: A4;
            margin-top: 5mm; 
            margin-bottom: 5mm;
        }
    }

    //127px(altura do footer) ou 80px(altura do footer2)
    padding: 0px 0px 80px;
`;

export const ContentContainer = styled.div `
    margin: 0px auto;
    max-width: 1000px;
`;

export const ReportContainer = styled.div `
    margin: 50px auto;
    max-width: 1000px;
    .br-table {
        width:100%;
    }
    tr > th {
        color: #1351B4;
        text-align: center;
    }

    td:first-child { width: 30% !important }

    @media print {
        .br-table table {
            --table-border: 1px solid var(--color-secondary-04);
            --table-padding: var(--spacing-scale-3x);
            --table-shadow: none;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact; 
        }
        .br-table thead {
            display: table-header-group;
            margin: 0;
        }  
        .br-table tr {
            display: table-row;
            margin: 0;
            box-shadow: var(--table-shadow);
        } 
        .br-table td, .br-table th {
            display: table-cell;
            padding: var(--table-row-size) calc(var(--table-padding)*0.5);
        }
        .br-table tr > th {
            text-align: center;
        }
        .br-table tbody {
            display: table-row-group;
        }
        .br-table[data-selection] tbody tr {
            position: static;
        }
        .br-table[data-selection] tbody td:first-child {
            padding-left: var(--table-padding);
            padding-right: calc(var(--table-padding)*0.5);
            position: static;
        }
        .br-table td, .br-table th {
            display: table-cell;
            padding: var(--table-row-size) calc(var(--table-padding)*0.5);
        }
        .br-table[data-selection] tbody td {
            padding-left: calc(var(--table-padding)*0.5);
        }
        .br-table td:first-child, .br-table th:first-child {
            padding-left: var(--table-padding);
        }
        .br-table thead td,  .br-table thead th {
            --table-border: 0;
            background: var(--table-header-background);
        }

    }    
`;

export const TitleContainer = styled.div `
    padding: 30px 0px 0px;
    display: flex;
    justify-content: space-between;

    @media print {
        padding: 0 0 0;
        justify-content: center !important;
    }
`;

export const IntervalTitle = styled.div `
    padding: 0px 0px 0px;
    margin-bottom: 15px;
    text-align: end;

    label {
       font-size: 1em;
    }
    @media print {
        margin-top: 40px !important;
    }
`;

export const SelectContainer = styled.div `
    width: 380px;
    height: 64px;
    display: grid;

    //border: 1px solid;
    //border-color: red;
`;

export const SelectPagesContainer = styled.div `
    width: 55px;
    height: 64px;
    display: grid;

    //border: 1px solid;
    //border-color: red;
`;

export const PaginationContainer = styled.div `
    margin-top: 20px;
    padding: 0px 30px;

    //border: 1px solid;
    //border-color: red;
`;