import React, { Component } from 'react';

import Header from './Components/Header/index';
//import SubHeader from './Components/SubHeader/index';
import Footer from './Components/Footer/index';
import Routes from './routes';
import GlobalStyle from './styles/global';

import '../node_modules/@govbr/dsgov/dist/dsgov.min.css';

export default class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        showInfo: false,
        userInfo: {
          name: '',
          picture: ' ',
          organ: '',
          perfil: ''
        },
        scrollAtivated: false,
    };
  };

  handleScrollAtivated() {
    if(document.getElementById('container').offsetHeight + 80 >= window.innerHeight) {
      this.setState({
        scrollAtivated: true,
      });
    } else {
      this.setState({
        scrollAtivated: false,
      });
    }
  };

  componentDidMount() {
    if (!!localStorage.getItem('user') && !!localStorage.getItem('user_picture')){
      const user = JSON.parse(localStorage.getItem('user'));
      const user_picture = localStorage.getItem('user_picture');

      this.setState({
          userInfo: {
            name: user.nome_atendente,
            picture: user_picture,
            organ: user.orgao,
            perfil: user.perfil
          }
      });
    }

    this.handleScrollAtivated();

    window.addEventListener('resize', () => {
      this.handleScrollAtivated();
    }, true);
  }

  handleShowInfo(appear) {
    this.setState({
      showInfo: appear, 
    });
  }

  handleName(name) {
    this.setState({
      userInfo: {
        name: name,
        picture: this.state.userInfo.picture,
        organ: this.state.userInfo.organ,
        perfil: this.state.userInfo.perfil
      }  
    });
  }

  handleOrgan(organ) {
    this.setState({
      userInfo: {
        name: this.state.userInfo.name,
        picture: this.state.userInfo.picture,
        organ: organ,
        perfil: this.state.userInfo.perfil
      }
    });
  }
  
  handlePerfil(perfil) {
    this.setState({
      userInfo: {
        name: this.state.userInfo.name,
        picture: this.state.userInfo.picture,
        organ: this.state.userInfo.organ,
        perfil: perfil
      }
    });
  }

  handlePicture(picture) {
    this.setState({
      userInfo: {
        name: this.state.userInfo.name,
        picture: picture,
        organ: this.state.userInfo.organ,
        perfil: this.state.userInfo.perfil
      }
    });
  }

  render() {
    return (
      <div id='container'>
        <Header showInfo={this.state.showInfo} handleUser={this.state.userInfo}/>
        <Routes
            handleShowInfo = {(appear) => this.handleShowInfo(appear)}
            handleName = {(name) => this.handleName(name)} 
            handleOrgan = {(organ) => this.handleOrgan(organ)}
            handlePerfil = {(perfil) => this.handlePerfil(perfil)}
            handlePicture = {(picture) => this.handlePicture(picture)}
            handleScrollAtivated = {() => this.handleScrollAtivated()}
        />
        <Footer scroll={this.state.scrollAtivated}/>
        <GlobalStyle />
      </div>
    );
  }
}
